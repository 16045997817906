@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import '~react-toastify/dist/ReactToastify.min.css';
body {font-family: "Poppins", sans-serif;color: #2b2b2b;font-size: 16px;}
* {box-sizing: border-box;}
::-webkit-input-placeholder{color:#cccccc!important;}
:-ms-input-placeholder{color:#cccccc!important;}
::placeholder{color:#cccccc!important;}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0;}
/* Firefox */
input[type=number]{-moz-appearance:textfield;}
input[type=date]:invalid::-webkit-datetime-edit{color:#cccccc;}
.css-1jqq78o-placeholder{color:#ccc;}
img {max-width: 100%;height: auto;}
header {padding: 10px 0;box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);position: relative;z-index: 3;}
.header_nav {text-align: right;}
.header_nav ul {list-style: none;margin-bottom: 0;justify-content: flex-end;}
.header_nav ul li a {text-decoration: none;color: #2b2b2b;padding: 10px 15px 10px 0;}
.header_nav ul li a:hover {color: #B10D28;}
.header_nav ul li.login_signup_btn a {padding: 10px 20px;}
.header_nav ul li.login_signup_btn a:hover, .sub-menu .quote_btn {background: #B10D28;color: #fff;border-radius: 100px;}
.header_nav ul ul.sub-menu .quote_btn {display: inline-block;padding: 0 15px;border: 1px solid #B10D28;}
.header_nav ul ul.sub-menu .quote_btn:hover {background: #fff;}
.header_nav ul ul.sub-menu .quote_btn:hover a {color: #B10D28;}
.header_nav ul ul.sub-menu .quote_btn a {display: inline-block;color: #fff;}
.header_nav ul li {position: relative;text-align: center;}
.header_nav ul.sub-menu {padding-left: 0;text-align: left;position: absolute;left: 0;top: 100%;display: none;min-width: 250px;background: #fff;padding: 10px 20px 20px 20px;z-index: 3;}
.header_nav ul.sub-menu li {text-align: left;margin-top: 10px;line-height: normal;}
.header_nav ul.sub-menu li a {padding: 10px 0;}
.header_nav ul li:hover .sub-menu {display: block;}
.logo_wrap img {height: 60px;max-width: 100%;}
.logo_wrap a:focus-visible{outline: none;}
.stickyHeader {background: #fff;position: fixed;z-index: 9;width: 100%;box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);}
.hero_blk {background: url('../public/img/Cooling-Installation-Main-2.jpg') no-repeat;padding:100px 15px 50px 15px;position: relative;background-size: cover;}
.hero_inr {position: relative;z-index: 2;}
.hero_blk::after {content: '';position: absolute;width: 100%;height: 100%;top: 0;left: 0;background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0) 2%, rgba(0, 0, 0, 1) 54%, rgba(0, 0, 0, 1) 100%);opacity: 0.7;z-index: 1;}
.hero_inr h1 {font-size:34px;line-height:40px;color: #fff;}
.hero_inr p {font-size: 18px;color: #fff;margin: 30px 0 40px 0;}
.red_btn {background: #B10D28;border: 1px solid #B10D28;color: #fff;text-decoration: none;padding: 10px 20px;border-radius: 100px;
text-transform: capitalize;display: inline-block;}
.red_btn:hover {background: #fff;color: #B10D28;border-color: #B10D28;}
.btn-primary:active {background-color: #B10D28;border-color: #B10D28;}
.btn-primary:focus {border-color: #B10D28;}
.installation_reason_blk {background: #212529;padding: 100px 0;}
.installation_reason_title h2 {text-align: center;color: #fff;margin-bottom: 40px;}
.installation_reason_inr>.row>.col-12:not(.installation_reason_title) {display: flex;justify-content: center;}
.inst_reason_box {background: #fff;border-radius: 10px;padding: 40px;margin-bottom: 25px;}
.inst_reason_box .icon {width: 40px;height: 40px;background: #feeceb;display: flex;justify-content: center;align-items: center;border-radius: 100px;font-size: 24px;font-weight: 600;color: #b10d28;margin-bottom: 15px;}
.inst_reason_box P {color: #2b2b2b;margin-bottom: 0;font-size: 16px;}
.replace_system_blk {padding:50px 0;}
.replace_system_blk .row .col-12 {display: flex;align-items: center;}
.replace_system_content p {margin-bottom: 0;}
.replace_system_img {display: flex;justify-content: center;align-items: center;border-radius: 10px;overflow: hidden;}
.replace_system_content h3{font-size:30px;}
.review_stars {margin-bottom: 10px;display: block;}
.reviews_item p {margin-bottom: 10px;}
.reviews_item p:last-child {margin-bottom: 0;}
.customer_reviews_blk {background:#f2f2f2;padding:50px 0;}
.customer_reviews_blk .slick-initialized .slick-slide>div {width: 100%;}
.sec_title {text-align: center;margin-bottom:20px;}
.sec_title h2 span {color: #b10d28;}
.customer_reviews .reviews_item {background: #fff;display: inline-block;padding: 20px 30px;border-radius: 10px;min-height: 180px;height: 100%;}
.customer_reviews .slick-prev {background:#fff url("../public/img/arrow-prev.svg") no-repeat;border: 0px;font-size: 0px;width: 40px;height: 40px;position: absolute;left: -10px;top: 50%;z-index: 1;cursor: pointer;transform: translateY(-50%);box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);border-radius: 50px;}
.customer_reviews .slick-next {background:#fff url("../public/img/arrow-next.svg") no-repeat;border: 0px;font-size: 0px;width: 40px;height: 40px;position: absolute;right: -10px;top: 50%;z-index: 1;cursor: pointer;transform: translateY(-50%);box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);border-radius: 50px;}
.slick-dots {display: flex !important;justify-content: center;width: 100%;position: relative;flex-wrap: wrap;margin-top: 15px;list-style: none;padding-left: 0;}
.slick-dots li button {background: #96A0AC;border: none;outline: none;width: 10px;height: 10px;font-size: 0;display: block;margin: 0 2px;border-radius: 20px;cursor: pointer;}
.slick-dots li.slick-active button {background: #b10d28;}
.review_stars ul {list-style: none;display: flex;padding: 0;margin-bottom: 0;}
.customer_service_inr, .subscribe_inr {max-width:650px;margin: 0 auto;padding: 0 15px;}
.appointment_service {display: flex;}
.appointment_service .service_blk {text-align: center;width: calc(50% - 20px);padding: 0 20px;}
.appointment_service .service_blk:first-child {border-right: 1px solid #ddd;}
.customer_service_blk {padding: 100px 0;}
.icon_circle {margin-bottom: 20px;}
.service_blk a {color: #2b2b2b;}
.subscribe_blk {background: #fff;padding:50px 0;}
.subscribe_inr {text-align: center;}
.subscribe_inr h2 {color: #2b2b2b;margin-bottom:30px;font-size: 30px;}
.subscribe_inr input {border-radius: 10px;padding: 10px 15px;}
.subscribe_btn {padding: 10px 30px;color: #fff;background-color: #b10d28;border-color: #b10d28;border-radius: 0 100px 100px 0;min-width: 160px;}
.subscribe_btn:hover, .subscribe_btn:active {color: #b10d28;background-color: #fff;border-color: #b10d28;}
.subscribe_blk .row>.col-auto{width:100%;display:flex;justify-content:center;}
.subscribe_input_blk{max-width:550px;margin:0 auto;}
.subscribe_blk .row>.col-auto:first-child input {border-radius: 100px 0 0 100px;border: 1px solid #b10d28;border-right:none;width:100%;}
.error_msg {margin-top: 10px;}
.error_msg p {font-size: 14px;}
.error_msg p:empty {display: none;}
.error_msg p:last-child {margin-bottom: 0;}
.subscribe_blk .row>.col-auto:first-child input:focus-visible {outline: none;}
.SubscribeError{color:#FF0000;}
.SubscribeSuccess{color:#26ca26;}
.btn-check:focus+.btn-primary, .btn-primary:focus {color: #b10d28;background-color: #fff;border-color: #B10D28;box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .5);}
.footer {background: #212529;padding: 80px 0 15px 0;}
.footer_logo img {filter: brightness(100);height: 60px;}
.app_store_blk ul {list-style: none;display: flex;align-items: center;justify-content: flex-end;}
.app_store_blk ul li {margin-left: 15px;}
.footer_menu_title h3 {font-size: 20px;color: #fff;}
.footer_menu_blk {margin-top: 50px;}
.footer .footer_menu {margin-bottom: 0;padding-left: 0;list-style: none;}
.footer .footer_menu li {margin: 15px 0;}
.footer .footer_menu li a {text-decoration: none;color: #868585;font-size: 16px;}
.footer_copyright_wrap {margin-top: 50px;border-top: 1px solid #353c42;padding-top: 15px;}
.legal_menu_wrap ul, .footer_social_link_wrap ul {margin-bottom: 0;padding-left: 0;list-style: none;display: flex;}
.legal_menu_wrap, .footer_social_link_wrap, .copyright_blk{display: flex;justify-content: center;align-items:center;}
.copyright_blk {text-align: center;}
.legal_menu_wrap ul li, .footer_social_link_wrap ul li {margin-right: 20px;}
.legal_menu_wrap ul li:last-child, .footer_social_link_wrap ul li:last-child{margin-right:0;}
.legal_menu_wrap ul li a {text-decoration: none;color: #fff;}
.copyright_blk p {margin-bottom: 0;color: #868585;}
.footer_logo {margin-left: -13px;}
.legal_menu_wrap ul li a, .copyright_blk p {font-size: 14px;color:#868585;}
.footer .footer_menu li a:hover, .legal_menu_wrap ul li a:hover {color: #fff;}
.footer_social_link_wrap ul li a:hover svg path {fill: #B10D28;}
.body-background-image .legal_menu_wrap ul li a:hover {color: #B10D28;}
.btn-primary.disabled, .btn-primary:disabled{background-color:#B10D28;border-color:#B10D28;}
@keyframes topMenuSlideDown {
    from {
        top: -80px;
        /*opacity: 0;*/
    }
    to {
        top: 0px;
        /*opacity: 1;*/
    }
}
/* Slomins Home Landing CSS */
body.body-background-image{background: url('../public/img/slomins_home_bg.jpg') top center no-repeat #fff;background-size: cover;}
.areainterest_content {text-align: center;padding: 10px 0 0;}
.areainterest_content .areaInterest_box .textlarge {text-align: center;font: 400 24px/36px Poppins;}
.box_services {text-align: center;margin: 20px auto;}
.box_services span.box_services_icon {background: #fff;box-shadow: 0px 0px 13px #eeebeb;display: inline-block;border: none;border-radius: 35px;padding: 30px 20px;max-width: 335px;}
.box_services span.box_services_icon a.button.alt.hollow {position: absolute;width: 100%;height: 100%;opacity: 0;left: 0;top: 0;}
.subtext_servicelink {display: flex;justify-content: center;}
.box_services h4.bar {width: 100%;border: 0px solid #B10D28;font-size: 28px;line-height: 36px;color: #B10D28;text-transform: uppercase;padding: 20px 0 0px;margin: 0 auto;}
.box_services .subtext_servicelink .button {border: none;text-transform: capitalize;color: #000;font-weight: 100;}
.box_services .subtext_servicelink .button:hover {border: none;text-transform: capitalize;color: #B10D28;font-weight: 100;background: none;}
.page_returning_content {text-align: center;padding: 0;}
.page_returning_content .returningCust_box .retcustxt {text-align: center;font: 400 24px/36px Poppins;color: #000;}
.page_returning_content .returningCust_box .retcustxt strong {text-align: center;font-weight: 400;color: #B10D28;}
.page_returning_content .returningCust_box .retcusbtn .button {text-decoration: none;background-color: #B10D28;border: 1px solid #B10D28;text-transform: uppercase;margin: 0;padding: 13px 40px;border-radius: 100px;cursor: pointer;display: inline-block;font-weight: 500;text-align: center;color: #fff;}
.page_returning_content .returningCust_box .retcusbtn .button:hover {background-color: #fff;border-color: #B10D28;color: #B10D28;}
p.textimg img {max-width: 20px;}
.areaInterest_box_logo .header_logo img {max-width: 260px;}
.box_services_inner a {text-decoration: none;}
.box_services .grid_center {display: flex;justify-content: center;}
.service_box_wrap {margin: 0 20px;text-align: center;}
.landing_footer {padding: 40px 0;background: transparent;}
.landing_footer .footer_inr {display: none;}
.landing_footer .footer_copyright_wrap {margin-top: 0;border-top: none;padding-top: 0;}
.landing_footer .copyright_blk p, .landing_footer .legal_menu_wrap ul li a {color: rgba(43, 43, 43, .5);}
.footer_social_link_wrap ul li a {width: 40px;height: 40px;border-radius: 50%;background-color: #b10d28;display: flex;justify-content: center;align-items: center;border: 1px solid #b10d28;}
.footer_social_link_wrap ul li a svg path {fill: #fff;}
.footer_social_link_wrap ul li a:hover {background-color:#fff;border: 1px solid #B10D28;}
.footer_social_link_wrap ul li a:hover svg * {fill: #B10D28;}
.landing_footer .footer_copyright_wrap .row {align-items: center;}
/* Slomins Home Landing CSS End */
/*Cooling Replacement Page Start*/
.replacement_hero {background: url('../public/img/Cooling-Service-Repair-Main-1.jpg') no-repeat;background-size: cover;}
.replacement_hero::after {background: linear-gradient(to left, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0) 2%, rgb(0, 0, 0) 54%, rgb(0, 0, 0) 100%);opacity: 0.2;}
.reason_blk_white {background: #fff;}
.reason_blk_white .installation_reason_title h2 {color: #2b2b2b;margin-bottom: 20px;}
.reason_blk_white .installation_reason_title p {text-align: center;}
.reason_blk_white .inst_reason_box {box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);}
.red_txt {color: #b10d28;}
.save_money_blk {padding-bottom: 100px;}
.save_money_content {text-align: center;}
.save_money_content img {border-radius: 10px;}
.save_money_content h2 {margin-bottom: 20px;}
/*Cooling Replacement Page end*/
/*Cooling Service Repair Page Start*/
.repair_hero {background: url('../public/img/Cooling-Replacement-Main-1.jpg') no-repeat;background-size: cover;}
.repair_hero::after {background: linear-gradient(to left, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0) 2%, rgb(0, 0, 0) 54%, rgb(0, 0, 0) 100%);opacity: 0.2;}
.replace_system_content .red_btn {margin-top: 20px;}
.reason_blk_bgimg {background: url('../public/img/Cooling-Service-Repair-5-Reasons-Module.jpg') no-repeat;background-size: cover;}
/*Cooling Service Repair Page End*/
/*Request a quote page start*/
.request_quote_blk {background:#fff;padding: 50px 0;}
.quote_form {max-width:1000px;margin: 0 auto;padding:40px 20px;background:#f2f2f2;border-radius: 10px;position: relative;}
.quote_form .nav-pills .nav-link, .company_history_blk .nav-pills .nav-link {color: #B10D28;border: 1px solid #B10D28;margin-right: 15px;border-radius: 100px;min-width: 190px;background: #fff;}
.quote_form .nav-pills {margin-bottom: 20px;justify-content: center;}
.quote_form .input-group {margin-bottom: 20px;}
.quote_form .nav-pills .nav-link.active, .quote_form .nav-pills .show>.nav-link, .quote_form .nav-pills .nav-link:hover, .company_history_blk .nav-pills .nav-link:hover, .quote_form .nav-pills .nav-link.active, .quote_form .nav-pills .show > .nav-link, .company_history_blk .nav-pills .nav-link.active{color: #fff;background-color: #B10D28;}
.submit_btn_blk {text-align: center;margin-bottom: 20px;}
.privacy_note {margin-top: 15px;}
.privacy_note p {font-size: 12px;color: #929395;}
.get_quote_pg .subscribe_blk{background:#f2f2f2;}
.quote_form .css-13cymwt-control, .quote_form .css-t3ipsp-control{min-height:45px;border-radius:5px;}
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu){border-top-right-radius:5px;border-bottom-right-radius:5px;}
/*Request a quote page end*/
/*Home-security-products-page start*/
.home_security_products_page .hero_blk {background: url('../public/img/Product-Listing-Header-Image-2.jpg') no-repeat center center/cover;padding:150px 15px 100px 15px;position: relative;}
.home_security_products_page .hero_inr {position: relative;z-index: 2;}
.home_security_products_page .hero_inr h1 {font-size:30px;line-height:normal;color: #b10d28;}
.home_security_products_page .hero_inr p {font-size: 16px;color: #b10d28;margin:0 0 10px 0;}
.home_security_products-tabs-blk {background: #ffffff;padding:50px 0 40px 0;}
.home_security_products-tabs-blk .nav-link svg path{fill: #fff;}
.home_security_products-tabs-blk .nav-link.active svg path {border-radius: 8px;background:#b10d28 !important;fill:#b10d28;}
.home_security_products-tabs-blk .nav-pills .nav-link.active, .home_security_products-tabs-blk .nav-pills .show>.nav-link {background: #fff !important;color:#b10d28;}
.home_security_products-tabs-blk .nav-link {border-radius:200px;background:#b10d28;padding: 15px;width: 100%;text-align: center;color: #fff;}
.home_security_products-tabs-blk .nav-pills {overflow-x: auto;width: 100%;flex-wrap: nowrap;background: #b10d28;justify-content: space-between;padding: 10px 5px;border-radius: 200px;margin-bottom: 40px;}
.home_security_products-tabs-blk .nav-pills .nav-item {width: calc(25% - 10px);margin: 0 5px;}
.home_security_products-tabs-blk .card h4{color: #2b2b2b;font-size: 24px;font-weight: 600;padding:0;line-height: 30px;text-align: left;}
.home_security_products-tabs-blk .card p {color: #2b2b2b;font-size: 14px;font-weight: 400;text-align: left;}
.home_security_products-tabs-blk #v-pills-tab {gap: 30px;}
.home_security_products-tabs-blk .card {border-radius: 0 65px;border: 0px solid #ddd;background: #fff;padding: 0;overflow: hidden;box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);}
.home_security_products-tabs-blk .card .card-content{padding:30px 40px;}
.home_security_products-tabs-blk .card .card-action a {color: #b10d28;text-align: center;font-size: 16px;
font-weight: 500;line-height: 19px;}
.home_security_products-tabs-blk .card .card-action:hover {background-color: #b10d28;color: #ffffff;}
.white_btn {background: #fff;color: #b10d28;text-decoration: none;padding: 10px 20px;border-radius: 100px;text-transform: capitalize;}
.white_btn:hover {background: #b10d28;border: 1px solid #b10d28;color: #fff;}
.home_security_products-tabs-blk .tab-pane h2 {margin: 36px 0px 36px 0px;}
.home_security_products_page .hero_blk::after {content: '';background: #b10d28;opacity: 0.2;}
.prd_learn_more{display:inline-block;}
/*Home-security-products-page end*/
/*Home security product detail page start*/
.home_security_product-detail .hero_blk {background: url('../public/img/home-security-product-banner.png') no-repeat center center/cover;padding:100px 15px 50px 15px;position: relative;}
.home_security_products-detail .hero_inr h1 {color: #000;font-size:34px;font-weight: 600;}
.home_security_products-detail .hero_inr p {color: #1E1E1E;font-size: 20px;font-weight: 400;line-height: normal;margin:15px 0;}
.home_security_products-detail .hero_blk::after {background: none !important;}
.home_security_product-detail .hero_inr {position: relative;z-index: 2;}
.home_security_product-detail .hero_inr h1 {font-size: 62px;line-height: 72px;color: #fff;}
.home_security_product-detail .hero_inr p {font-size: 18px;color: #fff;margin: 30px 0 40px 0;}
.key-features_blk {background: #212529;padding:50px 0 25px 0;}
.key-features_title h2 {font-size:30px;font-style: normal;font-weight: 600;text-align: center;color: #2b2b2b;margin-bottom:10px;}
.key-features_subtitle p {margin-bottom:20px;text-align: center;max-width: 80%;}
.key-features_inr>.row>.col-12 {display: flex;justify-content: center;}
.key-features_box {background: #fff;border-radius:10px;border:1px solid #ddd;padding:20px;margin-bottom:25px;text-align:center;width:100%;transition:all 0.3s ease-in-out;}
.key-features_box .icon {width:60px;height:60px;background: #feeceb;display: flex;justify-content: center;align-items: center;border-radius: 100px;font-size: 24px;font-weight: 600;color: #b10d28;margin:0 auto;}
.key-features_box h3 {color: #2b2b2b;margin-bottom: 0;font-size: 20px;font-weight: 600;margin:15px 0px;text-transform:capitalize;}
.key-features_box P {color: #2b2b2b;margin-bottom: 0;font-size: 16px;}
.get_slomin_shield-detial_blk {background:#f2f2f2;padding:50px 0;}
.get_slomin_shield-detial_blk .get_slomin_shield_content {padding:0 0 0 50px;}
.get_slomin_shield-detial_blk .get_slomin_shield_content h2 span {color: #b10d28;}
.get_slomin_shield-detial_blk .get_slomin_shield_content h2 {color: #2b2b2b;font-size:30px;font-weight: 600;line-height:normal;}
.get_slomin_shield-detial_blk .get_slomin_shield_content p {color: #2b2b2b;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;margin-bottom:15px;}
.prd_img_wrap {display:flex;justify-content: center;background: #b5b1b0;padding: 30px 0;border-radius: 20px;}
.home_security_products-detail-hero {background: #FEECEB !important;padding:100px 0 50px 0;}
.home_security_products-detail #disclaimer_blk+.subscribe_blk {background: #f2f2f2ff;}
/*Home security product detail page end*/
/*home automation page start*/
.home_automation_page .red_btn {background: #b10d28;border: 1px solid #b10d28;color: #fff;text-decoration: none;padding: 10px 20px;border-radius: 100px;text-transform: capitalize;}
.home_automation_page .red_btn:hover {background: #fff;color: #b10d28;}
.home_automation_page .white_btn {background: #fff;color: #b10d28;text-decoration: none;padding: 10px 20px;border-radius: 100px;border: 1px solid #fff;text-transform: capitalize;display: inline-block;}
.home_automation_page .white_btn:hover {background: #b10d28;border: 1px solid #b10d28;color: #fff;}
.home_automation_page .hero_blk {background: url("../public/img/Home-Automation-Hero-Image-1.jpg") no-repeat center center/cover;padding:100px 15px 50px 15px;position: relative;}
.home_automation_page .hero_blk::after, .home_security-camera_page .hero_blk::after, .home_professional-monitoring_page .hero_blk::after, .why-choose-the-slomins-shield .hero_blk::after, .business_overview_hero::after{background:rgba(0, 0, 0, 0.8);}
.home_automation_security-camera, .home_automation-tabs-blk {padding:50px 0px;}
.home_automation_security-camera_img {background: #fff;padding: 0;border-radius:0px;overflow: hidden;display: flex;justify-content: center;align-items: center;}
.home_automation_page .home_automation-tabs-blk .nav-pills {overflow-x: auto;width: 100%;flex-wrap: nowrap;border-radius: 0;background: transparent;padding: 0;gap: 0;justify-content: center;}
.home_automation-tabs-blk {border-radius: 0;background: #f2f2f2;padding: 50px 0;}
.home_automation-tabs-blk .nav-pills .nav-item {margin: 0 5px;width: calc(25% - 10px);}
.home_automation-tabs-blk .nav-pills .nav-item:first-child {margin-left: 0;}
.home_automation-tabs-blk .nav-pills .nav-item:last-child {margin-right: 0;}
.home_automation-tabs-blk .nav-link.active svg path {border-radius:10px;background: #ffffff !important;fill:#fff;}
.home_automation-tabs-blk .nav-pills .nav-link.active, .home_automation-tabs-blk .nav-pills .show>.nav-link, .home_automation-tabs-blk .nav-link:hover{border-radius: 10px;  background: #b10d28 !important;color: #fff;border-color: #b10d28;}
.home_automation-tabs-blk .nav-link {border-radius: 10px;padding: 15px 25px;width: 100%;text-align: left;color: #2b2b2b;border: 1px solid #ddd;background: #fff;text-align: center;}
.home_automation-tabs-blk .nav-pills {gap: 0;overflow-x: auto;width: 100%;flex-wrap: nowrap;justify-content: center;}
.home_automation-tabs-blk .card h4 {color: #2b2b2b;text-align: center;font-size: 24px;font-weight: 600;padding: 14px 0px 0px;line-height: 30px;text-align: left;}
.home_automation-tabs-blk .card p {color: #2b2b2b;font-size: 14px;font-weight: 400;text-align: left;}
.home_automation-tabs-blk .card {border-radius: 10px;border: 1px solid #ddd;background: #fff;padding: 20px;}
.home_automation-tabs-blk .card .card-action a {color: #b10d28;text-align: center;font-size: 16px;font-weight: 500;line-height:20px;}
.home_automation-tabs-blk .card .card-action {border-radius:10px;border: 1px solid #b10d28;background: #fff;padding:10px;text-align: center;width: 100%;display: block;color: #b10d28;text-decoration: none;}
.home_automation-tabs-blk .card .card-action:hover {background-color: #b10d28;color: #ffffff;}
.home_security-inr-sec li .nav-link span {white-space: pre;}
.red_btn {background: #b10d28;border: 1px solid #b10d28;color: #fff;text-decoration: none;padding: 10px 20px;border-radius: 100px;
text-transform: capitalize;}
.red_btn:hover {background: #fff;color: #b10d28;}
.white_btn {background: #fff;color: #b10d28;text-decoration: none;padding: 10px 20px;border-radius: 100px;text-transform: capitalize;border: 1px solid #b10d28;}
.white_btn:hover {background: #b10d28;border: 1px solid #b10d28;color: #fff;}
.home_mob-automation-tabs-blk .slm-btn-primary {border: 1px solid #b10d28;background: #b10d28;text-decoration: none;padding: 10px 20px;border-radius: 40px;transition: color 0.25s, background-color 0.25s, border-color 0.25s;}
.home_mob-automation-tabs-blk .slm-btn-primary:hover {border: 1px solid #ffffff;background: #ffffff;color: #b10d28;}
.home_automation_page .hero_blk .white_btn {border: 1px solid #ffffff;}
.home_automation_page .hero_blk .white_btn:hover {border: 1px solid #ffffff;}
.home_automation_page .slm-btn-primary {background: #ffffff;color: #b10d28;border: 1px solid #b10d28;text-decoration: none;padding: 10px 20px;border-radius: 40px;transition: color 0.25s, background-color 0.25s, border-color 0.25s;display: inline-block;}
.home_automation_page .slm-btn-primary:hover {background: #b10d28;color: #fff;border: 1px solid #b10d28;}
.home_automation_security-camera_content h2 {color: #2b2b2b;font-size:30px;font-weight: 600;}
.home_automation-tabs-blk .tab-content {background: #fff;padding: 30px;border-radius: 10px;margin-top: 30px;}
.home_automation_security-camera_content p {color: #2b2b2b;font-size: 16px;font-weight: 400;}
.home_automation_page .subscribe_blk, .home_security-camera_page .subscribe_blk{margin:0;border-radius:0;background: #f2f2f2;}
.home_mob-automation-tabs-blk {background-color:#f2f2f2;padding:50px 0px;}
.home_mob-automation-tabs-blk #v-pills-tab {width: 60%;}
.home_automation-tab-inr h2 {color: #2b2b2b;font-size:30px;font-weight: 600;}
.home_automation-tab-inr h2 span {color: #b10d28;}
.home_mob-automation-heading{margin-bottom: 30px;}
.home_mob-automation-heading a img {max-height: 40px;margin: 0 5px;}
.home_mob-automation-tabs-blk .nav-pills .nav-link.active, .nav-pills .show>.nav-link {background: #ffffff !important;border-left: 2px solid #b10d28;color: #b10d28 !important;border-left: 5px solid #b10d28;}
.home_mob-automation-tabs-blk .nav-pills .nav-link:hover {color: #b10d28;}
.home_mob-automation-tabs-blk .nav-pills .nav-link {font-size: 20px;font-weight: 600;line-height: normal;border-radius:10px;background: #fff;margin:0 0px 15px 0px;padding: 20px;text-align: left;color: #1e1e1e !important;}
.home_mob-automation-tabs-blk p {color: #2b2b2b;font-size: 14px;font-weight: 400;line-height: normal;margin-bottom:15px;}
.home_automation_page .home_mob-automation-tabs-blk .nav-pills .nav-link p {margin-bottom: 0;}
.home_mob-automation-tabs-blk .tab-pane {border-radius:10px;background:#fff;margin:0 0 15px 0px;padding:20px 10px;}
.home_automation-cards {padding: 50px 0;}
.home_automation-cards-img1 {background:url('../public/img/hassle-free-img.png');background-size: cover;border-radius:0 65px 65px 65px;padding:0;height: 100%;min-height:450px;position: relative;overflow: hidden;}
.home_automation_cards_cont {position: absolute;left: 0;bottom: 0;background: rgba(0, 0, 0, 0.8);padding:30px 20px;}
.home_automation-cards-img2 {background:url('../public/img/customize-home.png');background-size: cover;border-radius:65px 0 65px 65px;padding:0;height: 100%;min-height:450px;position: relative;overflow: hidden;}
.home_automation-cards h4 {color:#fff;font-size:24px;font-weight: 600;}
.home_automation-cards p {color:#fff;font-size: 16px;font-weight: 400;line-height: normal;margin-bottom:0;}
.home-slomins-support {padding:50px 0;background: #fff;}
.pricing-alm-blk h2 {color: #2b2b2b;font-size: 40px;font-weight: 600;}
.pricing-alm-blk span {color: #b10d28;}
.home-slomins-support .section_heading h4{display: inline-block;padding-bottom: 20px;position: relative;text-transform: capitalize;z-index: 1;}
.home-slomins-support-inr .our-offer-carousel .owl-dots .owl-dot.active span {background: #ee3b33 none repeat scroll 0 0;border-color: #ee3b33;}
.home-slomins-support-inr ._slm-item {background: #ffffff;border: 1px solid #ddd;padding:40px;border-radius:10px;overflow: hidden;position: relative;z-index: 1;transition:all 0.3s ease-in-out;}
.home-slomins-support-inr ._slm-item:hover, .key-features_box:hover{border-color: #B10D28;transition:all 0.3s ease-in-out;transform: translateY(-5px);}
.home-slomins-support-inr .our-offer-_slm-items.less-carousel .equal-height {margin-bottom: 30px;}
.home-slomins-support-inr ._slm-item h4 {color: #2b2b2b;text-align: center;font-size: 24px;font-weight: 600;line-height: 30px;margin: 20px;}
.home-slomins-support-inr ._slm-item p {color: #2b2b2b;text-align: center;font-size: 16px !important;font-weight: 400;line-height: normal;}
.home_automation_page .security_priority-blk .home-slomins-support-inr {padding:50px 0;}
.learn_more_btn_blk{margin-top:30px;}
.home_security-camera_manage_img, .home_automation_security-camera_img{border-radius: 10px;overflow: hidden;}
.home-slomins-support-inr ._slm-item img{max-width:80px;}
/*home automation page end*/
/* .home_security-camera  page */
.home_security-camera_page .hero_blk {background: url("../public/img/Remote-App-Main-Header-1.jpg") no-repeat right -350px center/cover;padding:100px 15px 50px 15px;position: relative;}
.home_security-camera_page .red_btn {background: #b10d28;border: 1px solid #b10d28;color: #fff;text-decoration: none;padding: 10px 20px;border-radius: 100px;text-transform: capitalize;}
.home_security-camera_manage {padding: 50px 0;}
.home_security-camera_page .red_btn:hover {background: #fff;color: #b10d28;}
.home_security-camera_page .white_btn {background: #fff;color: #b10d28;text-decoration: none;padding: 10px 20px;border-radius: 100px;text-transform: capitalize;}
.home_security-camera_page .white_btn:hover {background: #b10d28;border: 1px solid #b10d28;color: #fff;}
.home_security-camera_manage img {border-radius: 15px;}
.home_mob-security-camera-tabs-blk .slm-btn-primary {border: 1px solid #b10d28;background: #b10d28;text-decoration: none;padding: 10px 20px;border-radius: 40px;transition: color 0.25s, background-color 0.25s, border-color 0.25s;}
.home_mob-security-camera-tabs-blk .slm-btn-primary:hover {border: 1px solid #ffffff;background: #ffffff;color: #b10d28;}
.home_security-camera_page .hero_blk .white_btn {border: 1px solid #ffffff;}
.home_security-camera_page .hero_blk .white_btn:hover {border: 1px solid #ffffff;}
.home_security-camera_page .slm-btn-primary {background: #ffffff;color: #b10d28;border: 1px solid #b10d28;text-decoration: none;
padding: 10px 20px;border-radius: 40px;transition: color 0.25s, background-color 0.25s, border-color 0.25s;}
.home_security-camera_page .slm-btn-primary:hover {background: #b10d28;color: #fff;border: 1px solid #b10d28;}
.home_security-camera_manage_content h2 {color: #2b2b2b;font-size: 30px;font-weight: 600;margin-bottom:15px;}
.home_security-camera_manage_content h2 span {color: #b10d28;}
.home_security-camera_manage_content p {color: #2b2b2b;font-size: 16px;font-weight: 400;line-height: normal;margin-bottom:0px;}
.home_mob-security-camera-tabs-blk #v-pills-tab {width: 100%;}
.home_security-camera-tab-inr {padding: 100px 0px;}
.home_security-camera-tab-inr h2, .commitment_blk .sec_title h2 {color: #2b2b2b;font-size:30px;font-weight: 600;}
.home_security-camera-tab-inr h2 span {color: #b10d28;}
.home_mob-security-camera-tabs-blk .nav-pills .nav-link.active, .nav-pills .show>.nav-link {background: #ffffff !important;border-left: 2px solid #b10d28;color: #b10d28 !important;border-left: 5px solid #b10d28;}
.home_mob-security-camera-tabs-blk .nav-pills .nav-link:hover {color: #b10d28;}
.home_mob-security-camera-tabs-blk .nav-pills .nav-link {font-size: 20px;font-weight: 600;line-height: normal;border-radius:10px;background: #fff;margin:20px 0px;padding: 25px;text-align: left;color: #2b2b2b !important;}
.home_mob-security-camera-tabs-blk p {color: #2b2b2b;font-size: 14px;font-weight: 400;line-height: normal;}
.home_mob-security-camera-tabs-blk .tab-pane {border-radius: 15px;background: #ffdcda;margin:20px 0px;padding: 50px 100px;}
.home_security-camera-cards-img1 {background: linear-gradient(180deg, #000 0%, rgba(217, 217, 217, 0) 100%), url("../public/img/hassle-free-img.png");background-size: cover;border-radius: 15px;padding: 220px 80px 100px 80px;height: 100%;}
.home_security-camera-cards-img2 {background: linear-gradient(180deg, #000 0%, rgba(217, 217, 217, 0) 100%), url("../public/img/customize-home.png");background-size: cover;border-radius: 15px;padding: 220px 80px 100px 80px;height: 100%;}
.home-slomins-support .section_heading h2, .pricing-alm-blk h2 {color: #2b2b2b;font-size:30px;font-style: normal;font-weight: 600;}
.home-slomins-support .section_heading h2 span, .pricing-alm-blk span {color: #b10d28;}
.home-slomins-support .section_heading h4 {display: inline-block;padding-bottom: 20px;position: relative;text-transform: capitalize;z-index: 1;}
.home_security-camera_page .home-slomins-support {padding:50px 0;}
.home_security-camera_page .security_priority-blk .home-slomins-support-inr {padding:50px 0;}
.home-slomins-support .section_heading {margin-bottom: 30px;}
/*home automation page end*/
/*home professional-monitoring page start*/
.home_professional-monitoring_page .red_btn {background: #b10d28;border: 1px solid #b10d28;color: #fff;text-decoration: none;padding: 10px 20px;border-radius: 100px;text-transform: capitalize;}
.home_professional-monitoring_page .red_btn:hover {background: #fff;color: #b10d28;border: 1px solid #b10d28;}
.home_professional-monitoring_page .white_btn {background: #fff;color: #b10d28;text-decoration: none;padding: 10px 20px;border-radius: 100px;text-transform: capitalize;border: 1px solid #b10d28;}
.home_professional-monitoring_page .white_btn:hover {background: #b10d28;border: 1px solid #b10d28;color: #fff;}
.home_professional-monitoring_page .hero_blk .white_btn:hover {background: #b10d28;border: 1px solid #fff;color: #fff;}
.home_professional-monitoring_page .hero_blk {background: url("../public/img/Professional-Monitoring-Header-Image-1.jpg") no-repeat center center/cover;padding:100px 15px 50px 15px;position: relative;}
.home_professional-monitoring_page .slomins-professional {padding:50px 0px;}
.home_professional-monitoring_page .slomins-professional .slomins-professional-inr .section-heading {color: #2b2b2b;font-size:30px;font-weight: 600;margin-bottom:30px;}
.home_professional-monitoring_page .slomins-professional .slomins-professional-inr .section-heading span {color: #b10d28;}
.slomins-professional .row>.col-md-6 {display: flex;}
.home_professional-monitoring_page .slomins-professional-card {border-radius: 10px;border: 1px solid #ddd;background: #fff;padding: 20px;margin-bottom: 25px;transition: all 0.3s ease-in-out;}
.home_professional-monitoring_page .slomins-professional-card:hover{border-color: #B10D28;transition: all 0.3s ease-in-out;transform: translateY(-5px);}
.home_professional-monitoring_page .slomins-professional-card h5 {font-size:18px;font-weight: 600;line-height:20px;margin-bottom: 15px;}
.home_professional-monitoring_page .slomins-professional-card p {color: #2b2b2b;font-size: 16px;font-weight: 400;line-height: normal;}
.home_professional-monitoring_page .slomins-professional-card p:last-child{margin-bottom:0;}
.home_professional-monitoring_page .request-for-quote {border-radius: 10px;background: #feeceb;padding: 20px;text-align: center;}
.home_professional-monitoring_page .request-for-quote h4 {color: #2b2b2b;font-size: 18px;font-weight: 600;margin-bottom: 15px;}
.home_professional-monitoring_page .request-for-quote hr {margin:20px 0px;background: #b10d28;}
.home_professional-monitoring_page .request-for-quote .note {color: #353C42;font-size: 12px;font-weight: 400;line-height: normal;margin-top: 15px;}
.home_professional-monitoring_page .request-for-quote .white_btn {display:inline-block;}
.home_professional-monitoring_page .red_btn, .home_professional-monitoring_page .white_btn:hover svg path {fill: white;}
.home_professional-monitoring_page .got_covered_blk {background: #f2f2f2;padding: 50px 0px;}
.got_covered_img {text-align: right;}
.home_professional-monitoring_page .got_covered_blk .got_covered_content h2 {color: #2b2b2b;font-size:30px;font-weight: 600;margin-bottom:15px;}
.home_professional-monitoring_page .dedicated-staff_blk .dedicated-staff_content h2 {color: #2b2b2b;font-size:30px;font-weight: 600;margin-bottom:15px;}
.home_professional-monitoring_page .got_covered_blk .got_covered_content p {color: #2b2b2b;font-size: 16px;font-weight: 400;line-height: normal;margin-bottom:15px;}
.home_professional-monitoring_page .dedicated-staff_blk {padding:50px 0px;}
.home_professional-monitoring_page .dedicated-staff_blk .dedicated-staff_content p {color: #2b2b2b;font-size: 16px;font-weight: 400;line-height: normal;margin-bottom:15px;}
.home_professional-monitoring_page .customer_reviews_blk h2 {font-size:30px;font-style: normal;font-weight: 600;}
.home_professional-monitoring_page .alarm_left_block {width: 50%;}
.home_professional-monitoring_page .subscribe_blk {margin:0;border-radius:0;}
.home_professional-monitoring_page .pricing-alm-blk .row {height: 100%;}
.home_professional-monitoring_page .pricing-alm-blk {padding: 50px 0;margin: auto;text-align: center;background: #fff;}
.home_professional-monitoring_page .pricing-alm-blk .card {box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;border-radius:10px;height: 100%;border: 2px solid #ffffff00;}
.home_professional-monitoring_page .pricing-alm-blk .card-body {padding: 40px;text-align: left;}
.home_professional-monitoring_page .pricing-alm-blk .card-body ul {padding: 0px 10px;}
.home_professional-monitoring_page .pricing-alm-blk .card-body ul li {color: #2b2b2b;font-size: 14px;font-weight:400;line-height: normal;text-align: left;margin: 15px 0px;}
.home_professional-monitoring_page .pricing-alm-blk .btn-dark {background-color: black;}
.home_professional-monitoring_page .pricing-alm-blk .btn-dark:hover {background-color: #343a40;}
.home_professional-monitoring_page .pricing-alm-blk .note {font-size: 14px;font-style: normal;font-weight: 400;}
.home_professional-monitoring_page .pricing-alm-blk .btn-alm-top {width: fit-content;border-radius: 40px;background: #f2f2f2;color: #2b2b2b;
text-align: center;font-size: 18px;font-style: normal;font-weight: 600;line-height: 123%;}
.home_professional-monitoring_page .pricing-alm-blk .card-body h2 {color: #2b2b2b;text-align: center;font-size:30px;font-weight: 600;margin: 25px 0px;text-align: left;}
.home_professional-monitoring_page .pricing-alm-blk .card-body span {color: #2b2b2b;font-size: 14px;font-weight: 400;line-height: 123%;}
.home_professional-monitoring_page .pricing-alm-blk .btn-alm-bottom {color: #B10D28;margin-top: 10px;font-size: 16px;font-weight: 600;line-height: normal;border-radius:10px;border: 1px solid #B10D28;padding: 15px 30px;width: 100%;}
.home_professional-monitoring_page .pricing-alm-blk .pricing-column .card {transition: transform 0.3s ease, border-color 0.3s ease;}
.home_professional-monitoring_page .pricing-alm-blk .pricing-column .card:hover {transform: translateY(-5px);border: 2px solid #B10D28;}
.home_professional-monitoring_page .pricing-alm-blk .pricing-column:hover .btn-alm-top {background-color: #FEECEB;color: #B10D28;}
.home_professional-monitoring_page .pricing-alm-blk .pricing-column:hover .btn-alm-bottom {border: 1px solid #B10D28;background: #B10D28;color: #fff;}
.pricing-alm-blk h2 {color: #2b2b2b;font-size:30px;font-weight: 600;margin-bottom:15px;}
.pricing-alm-blk span {color: #b10d28;}
.home_professional-monitoring_page .home-slomins-support-inr .our-offer-_slm-items.less-carousel .equal-height {margin-bottom: 30px;}
.home_professional-monitoring_page .home-slomins-support-inr ._slm-item h4 {color: #2b2b2b;text-align: center;font-size: 24px;font-weight: 600;line-height: 123%;margin: 15px;}
.home_professional-monitoring_page .home-slomins-support-inr ._slm-item p {color: #2b2b2b;text-align: center;font-size: 16px;font-weight: 400;line-height: normal;}
.home_professional-monitoring_page .security_priority-blk .home-slomins-support-inr {padding:50px 0;}
/*home_professional-monitoring_page end*/ 
/*why-choose the-slomins-shield start*/
.why-choose-the-slomins-shield .red_btn {background: #b10d28;border: 1px solid #fff;color: #fff;text-decoration: none;padding: 10px 20px;
border-radius: 100px;text-transform: capitalize;display: inline-block;}
.why-choose-the-slomins-shield .red_btn:hover {background: #fff;color: #b10d28;}
.why-choose-the-slomins-shield .white_btn {background: #fff;color: #b10d28;text-decoration: none;padding: 10px 20px;border-radius: 100px;border: 1px solid #b10d28;text-transform: capitalize;margin-left:15px;display: inline-block;}
.why-choose-the-slomins-shield .white_btn:hover {background: #b10d28;border: 1px solid #fff;color: #fff;}
.why-choose-the-slomins-shield .get_slomin_shield_blk .white_btn{margin-left:0;}
.why-choose-the-slomins-shield .hero_blk {background: url("../public/img/slomins-customer-service-rep-on-a-call.jpg") no-repeat center center/cover;padding:100px 15px 50px 15px;}
.why-choose-the-slomins-shield .button_hero-blk{display: flex;flex-wrap: wrap;}
.why-choose-the-slomins-shield .slomins-professional .slomins-professional-inr .section-heading {color: #2b2b2b;font-size: 24px;font-weight: 600;margin-bottom:15px;}
.why-choose-the-slomins-shield .slomins-professional .slomins-professional-inr .section-heading span {color: #b10d28;}
.why-choose-the-slomins-shield .slomins-professional-card {border-radius: 15px;border: 1px solid #feeceb;background: #fff;padding: 20px !important;height: 100%;}
.why-choose-the-slomins-shield .slomins-professional-card h5 {font-size: 20px;font-weight: 600;line-height: normal;}
.why-choose-the-slomins-shield .slomins-professional-card p {color: #2b2b2b;font-size: 14px;font-weight: 400;line-height: normal;}
.why-choose-the-slomins-shield .request-for-quote {border-radius: 15px;background: #feeceb;padding: 20px !important;}
.why-choose-the-slomins-shield .request-for-quote h4 {color: #2b2b2b;font-size: 20px;font-style: normal;font-weight: 600;margin-bottom: 40px;}
.why-choose-the-slomins-shield .request-for-quote hr {margin: 40px 0px;}
.why-choose-the-slomins-shield .request-for-quote .note{color: #353C42;font-size: 12px;font-weight: 400;line-height: normal;margin-top: 15px;}
.why-choose-the-slomins-shield .request-for-quote .red_btn, .why-choose-the-slomins-shield .request-for-quote .white_btn{width: 100%;display: block;text-align: center;border: 1px solid #fff;}
.why-choose-the-slomins-shield .red_btn, .why-choose-the-slomins-shield .white_btn:hover svg path {fill: white;}
.why-choose-the-slomins-shield .trust-high-security_blk img{border-radius: 15px;}
.why-choose-the-slomins-shield .trust-high-security_blk .trust-high-security_heading h2{text-align: center;}
.why-choose-the-slomins-shield .trust-high-security_blk .trust_high_security_content h2, .why-choose-the-slomins-shield .trust-high-security_blk .trust-high-security_heading h2, .customer_review_blk .sec_title h2{color: #2b2b2b;font-size:30px;font-weight: 600;margin-bottom:40px;}
.why-choose-the-slomins-shield .trust-high-security_blk{padding:50px 0;}
.why-choose-the-slomins-shield .trust-high-security_blk .trust_high_security_content p{color: #2b2b2b;font-size: 16px;font-weight: 400;line-height: normal;margin-bottom:15px;}
.why-choose-the-slomins-shield .trust-high-security_blk .trust_high_security_content p:last-child{margin-bottom:0;}
.why-choose-the-slomins-shield .customer_reviews_blk h2, .customer_reviews_blk h2, .join_family_content h2{font-size:30px;font-weight: 600;}
.img_blk_grey{background: #f2f2f2;}
.trust_high_security_content h3{font-size:30px;}
.trust_high_security_content {padding: 0 50px;}
.img_blk_grey .trust_high_security_content{padding-left:0;}
.why-choose-the-slomins-shield .get_slomin_shield_blk {background: #fff;}
.why-choose-the-slomins-shield .home-slomins-support{background: #f2f2f2;}
.why-choose-the-slomins-shield .trust-high-security_blk .trust-high-security_heading span{color: #b10d28;}
.home_professional-monitoring_page .home-slomins-support {background: #f2f2f2;}
.home_professional-monitoring_page .subscribe_blk {background: #fff;}
/*why-choose the-slomins-shield end*/
/*About us overview start*/
.about_overview_hero{background: url('../public/img/about-us.jpg') no-repeat right top;background-size:cover;}
.about_overview_hero::after{background:linear-gradient(to left, rgba(0,0,0,0) 1%,rgba(0,0,0,0) 2%,rgb(0, 0, 0) 54%,rgb(0, 0, 0) 100%);opacity: 0.7;}
.commitment_blk {padding:50px 0;}
.commitment_blk .row > div {display: flex;}
.commitment_box {padding: 20px;border-radius: 10px;display: flex;align-items: center;text-align: center;background: #fff;flex-direction: column;width: 100%;transition:all 0.3s ease-in-out;border:1px solid #ddd;}
.commitment_icon {width: 60px;height: 60px;display: flex;justify-content: center;align-items: center;background: #feeceb;border-radius: 100px;padding: 15px;margin-bottom: 10px;}
.commitment_box h5 {margin-bottom: 0;}
.company_history_blk .nav-pills .nav-link{background:#fff;}
.company_history_blk .nav-pills {justify-content: center;}
.company_history_blk .tab-content {padding-top: 40px;}
.company_history_blk {background: #f2f2f2;padding: 50px 0;}
.timeline_wrap {display: flex;align-items: center;}
.timeline_img {width: calc(50% - 50px);margin-right: 50px;}
.timeline_img img{max-width:100%;border-radius:10px;}
.timeline_txt {width: 50%;}
.timeline_txt p:last-child {margin-bottom: 0;}
.service_info_blk {padding:50px 0;}
.service_info_blk .row>div {display: flex;}
.service_info_box {text-align: center;display: flex;flex-direction: column;align-items: center;border: 1px solid #ddd;padding: 20px;background: #fff;border-radius: 10px;transition:all 0.3s ease-in-out;}
.service_info_box:hover, .commitment_box:hover{border-color: #B10D28;background-color: #fff;-webkit-transition: all 0.4s ease-in-out;-moz-transition: all 0.4s ease-in-out;-ms-transition: all 0.4s ease-in-out;-o-transition: all 0.4s ease-in-out;transition: all 0.4s ease-in-out;box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 8%);transform: translateY(-5px);}
.service_info_icon {width: 60px;height: 60px;display: flex;justify-content: center;align-items: center;background: #feeceb;border-radius: 100px;margin-bottom: 15px;padding: 10px;}
.service_info_txt p {margin-bottom: 0;}
.join_family_blk {text-align: center;padding: 100px 0;background: url('../public/img/about-us.jpg') no-repeat right top;background-size:cover;position: relative;}
.join_family_blk::after{content: '';position: absolute;width: 100%;height: 100%;top: 0;left: 0;background: linear-gradient(to bottom, rgba(0,0,0,0) 1%,rgba(0,0,0,0) 2%,rgba(0,0,0,1) 54%,rgba(0,0,0,1) 100%);opacity: 0.7;z-index: 1;}
.join_family_content h2, .join_family_content p {color: #fff;}
.join_family_inr {z-index: 3;position: relative;}
.about_us_main .hero_box p:last-child{margin-bottom:0;}
/*About us overview end*/
/*About customer reviews start*/
.customer_review_hero{background: url('../public/img/Reviews-Header-n.jpg') no-repeat right top;background-size:cover;}
.customer_review_hero::after{background:linear-gradient(to left, rgba(254, 236, 235, 0) 1%,rgba(254, 236, 235, 0) 2%,rgb(254, 236, 235) 54%,rgb(254, 236, 235) 100%);opacity: 0.7;}
.customer_review_hero h1, .customer_review_hero p{color: #2b2b2b;}
.customer_review_hero h1 span{color:#B10D28;}
.customer_review_blk {padding:50px 0;}
.customer_img img {border-radius: 10px;}
.customer_review_blk .row {display: flex;align-items: center;}
.customer_review_content {padding-left: 50px;}
.review_btm p {margin-bottom: 0;}
.review_btm {border-left: 3px solid #B10D28;padding-left: 10px;}
.review_btm p {margin-bottom: 0;}
.customer_review_content img {max-width: 60px;margin-bottom: 15px;}
.customer_review_content>p {margin-bottom: 10px;}
.customer_review_black {background:#f2f2f2;padding:50px 0;}
.customer_review_black p, .customer_review_black h6{color:#2b2b2b;}
.customer_review_black .customer_review_content {padding-left: 0;padding-right: 60px;}
.customer_review_black .customer_img {position: relative;padding: 30px 30px 30px 0;z-index: 2;}
.customer_review_black .customer_img img {border-radius: 10px;position: relative;z-index: 2;}
.customer_review_pg .subscribe_blk {background: #f2f2f2;}
/*About customer reviews end*/
/*Location start*/
.location-page .hero_blk {background: url("../public/img/locations-banner.png") no-repeat center center/cover;padding:100px 15px 50px 15px;position: relative;}
.location-page .hero_blk h1,.location-page .hero_blk .hero_inr p{width: 100%;text-align: center;}
.location-page .button_hero-blk{display: flex;flex-wrap: wrap;justify-content: center;}
.location-page .button_hero-blk .red_btn{margin-left: 20px;}
.location-page .hero_blk::after {background: rgba(0, 0, 0, 1);opacity: 0.8;}
.button_hero-blk #selectInput{display: block;width: 30%;padding: .375rem .75rem;font-size: 1rem;font-weight: 400;line-height: 1.5;color: #212529;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: .25rem;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;border-radius: 24px;}
.location-page .subscribe_blk {padding: 50px 0;background: #f2f2f2;}
.location-page-cards {padding:50px 0 30px 0;}
.location-details-blk{padding-top:50px;}
.location-details-blk .container h2{color:#2b2b2b;font-size:30px;margin-bottom:15px;text-align:center;}
.location-details-blk .container a{display:inline-block;margin:0 0 15px 0;text-decoration:none;font-size:24px;}
.location-available-service .location-available-service-inr{justify-content:center;}
.location-details-blk .container p{color: #2b2b2b;font-size: 24px;font-weight:600;}
.location-available-service-inr .location-list-content{padding: 20px;}
.location-available-service-inr .location-list-content h3{color:#2b2b2b;font-size:20px;font-weight:600;margin-bottom:15px;text-transform:capitalize;text-align:left;}
.location-available-service{margin-top:15px;}
.location-available-service-inr .location-list-content ul{padding:0px;list-style:none;display:flex;flex-wrap:wrap;}
.location-available-service-inr .location-list-content ul li{display: inline-block;padding-left: 20px;background-image: url('../public/img/map-marker-icon.svg');background-size: 16px;background-repeat: no-repeat;background-position: left center;line-height: 20px;margin-bottom: 10px;color: #1E1E1E;font-size: 14px;font-weight: 400;padding-right: 12px;width:50%;text-align:left;}
.location-details-blk h4{font-size: 24px;}
.location-details-blk p {font-size: 14px;}
.location-page .slomins-professional {padding: 65px 130px;}
.location-page .got_covered_blk {background: #212529;padding: 110px 130px;}
.location-page .got_covered_blk .got_covered_content h2{color: #fff;font-size:30px;font-weight: 600;margin-bottom: 20px;}
.location-page .customer_reviews_blk h2{font-size:30px;font-style: normal;font-weight: 600;}
.get_slomin_shield_blk {background: #f5f5f5;padding: 100px;}
.get_slomin_shield_blk .get_slomin_shield_content h2 span{color: #b10d28;}
.location-page-cards h4{color: #FFF;font-size:40px;font-style: normal;font-weight: 600;margin-bottom:30px;}
.location-page .hero_inr p {margin: 0 0 15px 0;font-size: 16px;}
.location-page-cards .row>div {margin-bottom: 20px;}
.loc_content{position: relative;z-index:5;}
.loc_img_box {position: relative;border-radius:10px;overflow: hidden;}
.loc_img_box figure {position: relative;overflow: hidden;text-align: center;cursor: pointer;margin: 0;}
.loc_img_box figure img {position: relative;display: block;min-height: 100%;max-width: 100%;opacity: 0.8;width: 100%;}
.loc_img_box figure figcaption {padding:30px;color: #fff;font-size:20px;-webkit-backface-visibility: hidden;backface-visibility: hidden;}
.loc_img_box figure figcaption::before, .loc_img_box figure figcaption::after{pointer-events: none;}
.loc_img_box figure figcaption, .loc_img_box figure figcaption > a {position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
.loc_img_box figure figcaption > a {z-index: 1000;text-indent: 200%;white-space: nowrap;font-size: 0;opacity: 0;}
figure.loc_img_effect {background:#b10d28;}
figure.loc_img_effect img {opacity: 0.7;-webkit-transition: opacity 0.35s;transition: opacity 0.35s;}
figure.loc_img_effect:hover img {opacity: 0.4;}
figure.loc_img_effect figcaption::before, figure.loc_img_effect figcaption::after{position: absolute;top:20px;right:20px;bottom:20px;left:20px;content: '';opacity: 0;-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;transition: opacity 0.35s, transform 0.35s;}
figure.loc_img_effect figcaption::before {border-top: 1px solid #fff;border-bottom: 1px solid #fff;-webkit-transform: scale(0,1);transform: scale(0,1);}
figure.loc_img_effect figcaption::after {border-right: 1px solid #fff;border-left: 1px solid #fff;-webkit-transform: scale(1,0);transform: scale(1,0);}
figure.loc_img_effect h2 {padding-top:30%;-webkit-transition: -webkit-transform 0.35s;transition: transform 0.35s;-webkit-transform: translate3d(0,-20px,0);transform: translate3d(0,-20px,0);font-size: 22px;}
figure.loc_img_effect p {padding:10px;opacity: 0;-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;transition: opacity 0.35s, transform 0.35s;-webkit-transform: translate3d(0,20px,0);transform: translate3d(0,20px,0);}
figure.loc_img_effect:hover figcaption::before, figure.loc_img_effect:hover figcaption::after {opacity: 1;-webkit-transform: scale(1);transform: scale(1);}
figure.loc_img_effect:hover h2, figure.loc_img_effect:hover p {opacity: 1;-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
.loc_img_box .white_btn {background: transparent;border-color: #fff;color: #fff;font-size: 13px;text-transform:none;padding:10px;}
.loc_img_box .white_btn:hover{background:#fff;color:#b10d28;}
/*Location start*/
/*Referall Program Start*/
.dark_btn{background: #1E1E1E;border: 1px solid #1E1E1E;color: #fff;text-decoration: none;padding: 10px 20px;border-radius: 100px;text-transform: capitalize;}
.dark_btn:hover {background: #fff;color: #b10d28;}
.referral-program-page .hero_blk {background: url("../public/img/Refer-a-friend-hero.jpg") no-repeat center center/cover;padding:100px 15px 50px 15px;position: relative;}
.referral-program-page .hero_blk h1, .referral-program-page .hero_blk .hero_inr p{width: 100%;text-align: left;}
.referral-program-page .button_hero-blk{display: flex;flex-wrap: wrap;}
.referral-program-page .hero_blk::after {background: none;}
.referral-program-page .referral-program-save-blk img{border-radius: 10px;}
.referral-program-page .referral-program-save-blk .step{width: fit-content;background: #FEECEB;color: #B10D28;font-size: 16px;font-weight: 600;padding: 10px 20px;margin:15px 0px;border-radius:100px;border:1px solid #B10D28;}
.referral-program-page .referral-program-save-blk .join-referral-program_content h2,.referral-program-page .referral-program-save-blk .join-referral-program_heading h2{color:#2B2B2B;font-size:30px;font-weight: 600;margin-bottom:15px;}
.referral-program-page .referral-program-save-blk .join-referral-program_heading h2{text-align: center;margin-bottom:40px;}
.referral-program-page .referral-program-save-blk{padding:50px 0;}
.referral-program-page .referral-program-save-blk .join-referral-program_content p{color: #2B2B2B;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;margin-bottom:0px;}
.get_slomin_shield_blk .get_slomin_shield_content h2 span, .referral-program-page .referral-program-save-blk .join-referral-program_heading span{color: #b10d28;}
.referral-program-save-blk ol {list-style-type: none;counter-reset: custom-counter;padding: 0px;}
.referral-program-save-blk ol li {counter-increment: custom-counter;position: relative;margin-bottom:20px;padding-left:45px;}
.referral-program-save-blk ol li::before{content:counter(custom-counter);color:#fff;font-weight: 400;margin-right: 0;background: #B10D28;padding: 3px 10px;border-radius: 50%;font-size: 16px;width: 30px;height: 30px;display: inline-block;text-align: center;position: absolute;left: 0;top: 0;}
.referral-program-page .customer_reviews_blk h2{font-size:30px;font-weight: 600;}
.home-slomins-support-inr.text-center ._slm-item:nth-child(2) {border-right: 1px solid #ddd;}
.home-slomins-support-inr._slm-item-border-less ._slm-item {border: medium none;}
.home-slomins-support-inr .our-offer-_slm-items.less-carousel .equal-height {margin-bottom:30px;}
.join-referral-program_content{padding-right:0;padding-left:50px;}
.odd .join-referral-program_content{padding-left:0;padding-right:50px;}
.referral-program-save-blk .row{align-items:center;}
.join-referral-program_img{text-align:left;}
.odd .join-referral-program_img{text-align:right;}
.ref_grey_bg, .referral-program-page .home-slomins-support{background:#f2f2f2;}
.referral-program-page .customer_reviews_blk{background:#fff;}
.referral-program-page .customer_reviews .reviews_item{background:#feeceb;}
.referral-program-page .btn_blk a{display:inline-block;}
.referral-program-page .btn_blk{margin-top:20px;}
.referral-program-page .home-slomins-support .our-offer-items>div{display:flex;}
.referral-program-page .home-slomins-support-inr ._slm-item .ref_icon{width:80px;height:80px;background:#fdebea;padding:20px;display:flex;justify-content:center;align-items:center;border-radius:200px;margin:0 auto;}
.referral-program-page .home-slomins-support-inr ._slm-item .ref_icon img{max-width:100%;}
.referral-program-page .home-slomins-support-inr ._slm-item p:last-child{margin-bottom:0;}
.referral-program-page .home-slomins-support .our-offer-items{justify-content:center;}
/*Referall Program Start*/
/*Terms start*/
.term-of-use .hero_blk {background: url("../public/img/blog-banner.png") no-repeat center center/cover;padding:150px 15px 150px 15px;position: relative;}
.term-of-use .hero_blk::after {background: none;}
.terms_content p{color:#2B2B2B;font-size:16px;line-height: normal;} 
.terms_content h5, .terms_content p, .terms_content ul li, .terms_content ol li {color: #2b2b2b;font-size: 16px;line-height: normal;margin-bottom: 10px;text-align: justify;}
.terms_content h5 {	font-weight: 600;margin: 10px 0 0 0;}
.terms_content > h5:first-child {margin-top: 0;}
.terms_content {background: #f2f2f2;padding: 30px;margin:50px 0;border-radius: 10px;}
.terms_content ul, .terms_content ol{padding-left:17px;}
.terms_content p:last-child{margin-bottom:0;}
/*Terms end*/
/*Privacy policy start*/
.privacy-policy .hero_blk, .faq_page .hero_blk {background: url("../public/img/blog-banner.png") no-repeat center center/cover;padding:150px 15px 150px 15px;position: relative;}
/*Privacy policy end*/
/*License start*/
.license .hero_blk {background: url("../public/img/blog-banner.png") no-repeat center center/cover;padding:150px 15px 150px 15px;position: relative;}
.license .terms_content ul {list-style: none;padding-left: 0;}
.license .terms_content ul li {margin-bottom: 10px;}
.license .terms_content ul li:last-child{ margin-bottom:0;}
/*License end*/
/*Contact Us Start*/
.dark_btn{background: #2b2b2b;border: 1px solid #2b2b2b;color: #fff;text-decoration: none;padding: 10px 20px;border-radius: 100px;text-transform: capitalize;}
.dark_btn:hover {background: #fff;color: #b10d28;}
.contact-page .hero_blk, .get_quote_pg .hero_blk{background: url("../public/img/contact-us-banner.png") no-repeat center center/cover;padding:100px 15px 50px 15px;position: relative;}
.contact-page .hero_blk::after, .get_quote_pg .hero_blk::after{background:linear-gradient(270deg,transparent 1%,transparent 2%,#000 54%,#000);opacity:.7;}
.contact-box-blk{padding:50px 0;}
.contact-form-blk .contact-form-row{background:#fff;padding:40px 20px;border-radius:10px;max-width:80%;margin:0 auto;}
.contact-form-blk h3{color: #2B2B2B;text-align: center;font-size:30px;font-weight: 600;}
.contact-form-blk p{color: #2b2b2b;text-align: center;font-size:16px;font-weight: 400;}
.contact-form-blk form .form-label,.contact-form-blk form label{color: #2b2b2b;font-size: 14px;font-weight: 500;}
.contact-form-blk form input,.contact-form-blk form select,.contact-form-blk form textarea, .quote_form .input-group input, .quote_form .input-group select{padding: 10px;border-radius: 5px;background: #FFF;}
.quote_form .input-group .form-group {width: 100%;}
.contact-form-blk form .note{color:#2b2b2b;font-size:14px;font-weight:400;margin-bottom: 0;}
.contact-form-blk form .note a{color: #B10D28;}
.box-feature{border-radius:10px;border:1px solid #ddd;background:#FFF;padding:20px;transition: all 0.3s ease-in-out;}
.box-feature:hover{border-color:#B10D28;transform: translateY(-5px);transition: all 0.3s ease-in-out;}
.box-feature h5{color:#2b2b2b;font-size:20px;font-weight:600;margin:20px 0px;text-align:center;}
.red-bdr-btn:hover svg path {fill: #fff;}
.box-feature .subtext-phone p{color: #353C42;font-size: 12px;font-weight: 400;margin-top:10px;margin-bottom:0;}
.red-bdr-btn {background: #fff;color: #b10d28;border: 2px solid #b10d28;text-decoration: none;padding: 10px 20px;border-radius: 100px;text-transform: capitalize;}
.red-bdr-btn:hover {background: #b10d28;border:2px solid #b10d28;color: #fff;}
.contact-form-blk form .form-label, .contact-form-blk form label {margin:15px 0px auto;}
.contact-form-blk form .form-control{appearance: auto;color: #6b6767;font-size: 16px;}
.contact-form-blk .sb_btn_wrap{margin-bottom:0;text-align:center;margin-top:15px;}
.box-feature-icon{display:block;width:100%;text-align:center;}
.box-feature .subtext-phone{text-align:center;}
.contact-form-blk{background:#f2f2f2;padding:50px 0;}
.contact-form-blk .sb_btn_wrap button {margin-bottom: 15px;}
/*Contact Us End*/
/*FAQ page start*/
.faq_blk {background: #fff;padding: 50px 0;}
.need_help_box {padding: 20px;background: #fff;border-radius: 5px;border: 1px solid rgba(0,0,0,.125);margin-top: 40px;}
.subtext-phone a {color: #B10D28;text-decoration: none;font-weight: 700;display:inline-flex;align-items: center;margin-bottom: 10px;}
.subtext-phone a svg {margin-right: 15px;}
.subtext-phone a svg path{fill: #B10D28;}
.subtext-phone span {display: block;font-size: 12px;line-height: 20px;color: rgba(43,43,43,.5);}
.faq_blk .accordion-button{font-weight:600;}
.faq_blk .accordion-button:not(.collapsed) {color: #B10D28;background-color: rgba(177, 13, 40,.125);border-top-left-radius: 0;border-top-right-radius: 0;}
.faq_blk .accordion-button:focus {border-color: #b10d28;box-shadow: 0 0 0 .25rem rgba(177, 13, 40, .25);}
.faq_blk .accordion-button:focus-visible{outline:0;}
.faq_blk .accordion-body a {color: #2b2b2b;}
.faq_blk .accordion-body a:hover{color: #b10d28;}
.faq_blk .accordion-body p:last-child{margin-bottom:0;}
.faq_blk .accordion-button::after {background-image: url("../public/img/chevron-down.svg");}
.faq_blk .accordion-button:not(.collapsed)::after{background-image: url("../public/img/chevron-down-red.svg");}
.faq_box {margin-bottom: 30px;}
.faq_box:last-of-type{margin-bottom:0;}
.faq_blk .sec_title h1{font-size:34px;}
.faq_box .faq_sec_title h3{font-size:30px;}
.faq_page .subscribe_blk{background:#f2f2f2;}
/*FAQ page end*/
/*Blog page start*/
.blog_page .hero_blk {background: url("../public/img/blog-banner.png") no-repeat center center/cover;padding:150px 15px 150px 15px;position: relative;}
.blog_page .hero_blk::after{background: none;}
.blog_page .products-list-home .card .image-wrapper img, .related-blog .card .image-wrapper img{width: 100%;max-height: 250px;object-fit: cover;transition: .4s ease-in-out;}
.blog_page .products-list-home .card:hover .image-wrapper img, .related-blog .card:hover .image-wrapper img{transform: scale(1.1);}
.blog_page .products-list-home .card .image-wrapper, .related-blog .card .image-wrapper{overflow: hidden;}
.home_security-camera_manage img{border-radius: 10px;}
.blog_page .blog_tab-blk .nav-pills{overflow-x: auto;width: 100%;flex-wrap: nowrap;border-radius:60px;padding: 15px;border: 1px solid #FEECEB;background: #FFF;z-index: 5;position: relative;justify-content: space-between;}
.blog_tab-blk {border-radius:0;padding-top:60px;padding-bottom:60px;position: relative;bottom: 170px;margin-top:70px;margin-bottom:50px;}
.blog_tab-blk .nav-link.active svg path {border-radius:10px;background: #ffffff !important;fill: white;}
.blog_tab-blk .card .note, .related-blog .card .card-content .note{border-radius: 0;padding: 0;color: #858383;margin: 0;text-transform: capitalize;}
.blog_tab-blk .card .date, .related-blog .card .card-content .date{color:#858383;font-size: 14px;font-weight:normal;padding: 10px 0px;}
.card-content{text-align: left;}
.blog_tab-blk .nav-pills .nav-link.active, .blog_tab-blk .nav-pills .show > .nav-link {color: #fff;border-radius: 40px;background: #B10D28;}
.blog_tab-blk .nav-link {border-radius:40px;padding:15px 20px;width: 100%;text-align: left;color: #2b2b2b;}
.blog_tab-blk .nav-pills {overflow-x: auto;width: 100%;flex-wrap: nowrap;}
.blog_tab-blk .card h4, .related-blog .card .card-content h4 {color: #2b2b2b;font-size: 24px;font-style: normal;font-weight: 600;padding: 14px 0px 0px;line-height: 30px;text-align: left;}
.blog_tab-blk .card h4 a, .related-blog .card .card-content h4 a{color: #2b2b2b;text-decoration: none;font-size: 24px;}
.blog_tab-blk .card h4 a:hover, .related-blog .card .card-content h4 a:hover{color: #b10d28;}
.blog_tab-blk .card p, .related-blog .card .card-content p{color: #2b2b2b;font-size: 14px;font-style: normal;font-weight: 400;text-align: left;}
.blog_page .products-list-home .card, .related-blog .card{border-radius:15px;border: 1px solid #ddd;background: #fff;padding: 0;margin-bottom:5px;overflow:hidden;}
.blog_page .products-list-home .card .card-content, .related-blog .card .card-content{padding:20px;}
.blog_tab-blk .card .card-action a {color: #b10d28;text-align: center;font-size: 16px;font-style: normal;font-weight: 500;line-height: 19px;}
.blog_tab-blk .card .card-action {display: block;color: #b10d28;text-decoration: none;}
.blog_tab-blk .card .card-action:hover {color: #2b2b2b;}
.blog-tab-inr-sec li .nav-link span {white-space: pre;}
.get_slomin_shield_blk {background: #f2f2f2;padding:50px 0;}
.get_slomin_shield_inr>.row {align-items: center;}
.get_slomin_shield_content {padding: 0 50px;}
.get_slomin_shield_blk .get_slomin_shield_content h2 span {color: #b10d28;}
.get_slomin_shield_blk .get_slomin_shield_content h2 {color: #2b2b2b;font-size: 30px;font-weight: 600;line-height: normal;}
.get_slomin_shield_blk .get_slomin_shield_content p {color: #2b2b2b;font-size: 16px;font-weight: 400;line-height: normal;margin-bottom:15px;}
.get_slomin_shield_img {background: #16191e;border-radius: 0 45px 45px 45px;overflow: hidden;}
.quote_btn_wrap {display: flex;}
.blog_tab-blk .tab-pane h2 {margin:35px 0px 35px 0px;color: #fff;}
.blog_tab-blk .tab-pane p {color: #2b2b2b;}
.blog_page .hero_blk .white_btn {border: 1px solid #ffffff;}
.blog_page .hero_blk .white_btn:hover {border: 1px solid #ffffff;}
.blog_page .slm-btn-primary {background: #ffffff;color: #b10d28;border: 1px solid #b10d28;text-decoration: none;padding: 12px 24px;border-radius: 44px;transition: color 0.25s, background-color 0.25s, border-color 0.25s;}
.blog_page .slm-btn-primary:hover {background: #b10d28;color: #fff;border: 1px solid #b10d28;}
.blog_page .subscribe_blk {margin: -255px 0 0 0;border-radius: 0;background: #f2f2f2;}
.blog_tab-blk .tab-content {padding:30px 0 0 0;}
.blog_page .pagination {margin-bottom: 0;justify-content: center;}
.blog_page .pagination .page-item.disabled .page-link{color: #858383;border-color:#ddd;}
.blog_page .pagination .page-item:first-child .page-link {border-top-left-radius: 50px;border-bottom-left-radius: 50px;}
.blog_page .pagination .page-item:last-child .page-link {border-top-right-radius: 50px;border-bottom-right-radius: 50px;}
.blog_page .pagination .page-link {padding: 10px 20px;color:#b10d28;}
.blog_page .pagination .page-item.active .page-link {background-color: #b10d28;border-color: #b10d28;color:#fff;}
.blog_page .pagination .page-link:hover {color: #fff;background-color: #b10d28;border-color: #b10d28;}
.blog_page .pagination .page-link:focus {box-shadow: 0 0 0 .25rem rgba(177, 13, 40,.25);}
.products-list-home .row .col-12 {display: flex;margin-bottom: 20px;}
.blog_details_page .blog-bottom-content ul, .blog_details_page .blog-bottom-content ol{margin:15px 0;padding-left:20px;}
/*Blog page end*/
/*Blog details page start*/
.blog_details_page .hero_blk {background: url("../public/img/blog-banner.png") no-repeat center center/cover;padding: 100px 15px 50px 15px;position: relative;}
.blog_details_page .hero_blk::after {background: rgba(0, 0, 0, 1);opacity:0.8;}
.blog_details_page .hero_content h1 {font-size: 34px;}
.heading-tag{border-radius: 40px;background: #FEECEB;padding: 12px 16px;color: #b10d28;width: fit-content;margin: 16px 0px 0px 0px;}
.social-icons{text-align: right;}
.social-icons ul li{display:inline-block;margin-left:10px;}
.blog_details_page .hero_blk .white_btn {border: 1px solid #ffffff;}
.blog_details_page .hero_blk .white_btn:hover {border: 1px solid #ffffff;}
.blog_details_page .slm-btn-primary {background: #ffffff;color: #b10d28;border: 1px solid #b10d28;text-decoration: none;padding: 12px 24px;border-radius: 40px;transition: color 0.25s, background-color 0.25s, border-color 0.25s;}
.blog_details_page .slm-btn-primary:hover {background: #b10d28;color: #fff;border: 1px solid #b10d28;}
.blog_details_page .subscribe_blk {margin: 0px 110px 0px 110px;border-radius: 10px;}
.blog_details_page .blog-content-blk{margin: 50px 0px;}
.blog_details_page .blog-hero_img img{width: 100%;height: auto;}
.blog-hero-blk .blog-top-content{margin: 20px 0px;}
.blog-hero-blk .blog-top-content h2{color: #1E1E1E;font-size: 40px;font-style: normal;font-weight: 600;}
.blog-hero-blk .blog-top-content .blog-date{color: #1E1E1E;font-size: 16px;font-style: normal;font-weight: 500;}
.blog-bottom-content{margin: 40px 0px;}
.blog-bottom-content p{color: #1E1E1E;font-size: 14px;font-style: normal;font-weight: 400;line-height: normal;}
.blog-bottom-content h4{color: #1E1E1E;font-size: 24px;font-style: normal;font-weight: 600;margin: 40px 0px 7px 0px;}
.related-blog .related-blog-heading{color: #2b2b2b;font-size:34px;font-style: normal;font-weight: 600;margin-bottom:20px;text-transform:capitalize;}
.related-blog .related-blog-heading span{color: #b10d28;}
.related-blog {padding:50px 0;background:#f2f2f2;}
.blog_details_page .subscribe_blk{margin: 0px auto 0 auto !important;border-radius:0;}
.blog_date_cat_blk h2, .blog_date_cat_blk .blog-date {font-size: 16px;text-transform: capitalize;color: #858383;margin-bottom: 0;}
.blog_details_page .social-icons ul {margin-bottom: 0;padding-left: 0;display: flex;align-items: center;justify-content: flex-end;}
.blog_details_page .social-icons ul li {cursor: pointer;margin: 0 7px;width: 40px;height: 40px;background-color:#fff;border-radius: 50px;transition: .25s;display: flex;justify-content: center;align-items: center;border:1px solid #b10d28;}
.blog_details_page .blog-top-blk {align-items: center;}
.blog_details_page .social-icons ul li:last-child{margin-right:0;}
.blog_details_page .social-icons ul li svg path {fill: #B10D28;}
.blog_details_page .social-icons ul li:hover{background-color: #B10D28;}
.blog_details_page .social-icons ul li:hover svg path {fill: #fff;}
.blog_date_cat_blk {display: flex;align-items: center;}
.blog_date_cat_blk h2{margin-right: 10px;padding-right:10px;border-right:1px solid #858383;color: #b10d28;}
.blog_details_page .blog-bottom-content {margin: 20px 0;}
.blog_details_page .subscribe_blk {background: #fff;}
.blog_details_page .blog-bottom-content h2, .blog_details_page .blog-bottom-content h3, .blog_details_page .blog-bottom-content h4 {margin-top:15px;}
.blog_details_page .blog-bottom-content a {color: #2b2b2b;}
.blog_details_page .blog-bottom-content a:hover{color:#b10d28;}
.blog_details_page .blog-bottom-content h1, .blog_details_page .blog-bottom-content h2, .blog_details_page .blog-bottom-content h3, .blog_details_page .blog-bottom-content h4, .blog_details_page .blog-bottom-content h1 b, .blog_details_page .blog-bottom-content h2 b, .blog_details_page .blog-bottom-content h3 b, .blog_details_page .blog-bottom-content h4 b{font-size: 24px;font-weight:500;}
.blog_details_page .blog-bottom-content img {max-width: 100%;margin: 20px 0;display: block;}
.blog_details_page .hero_content h1 {font-size: 34px;max-width: 60%;margin: 0 auto;line-height: normal;}
.back_btn_blk {display: flex;margin-left: -13px;margin-bottom: 20px;}
.back_btn_blk a{background:url("../public/img/arrow-prev.svg") no-repeat left center;padding-left: 30px;font-size: 14px;background-size: 34px;color: #b10d28;text-decoration: none;}
.back_btn_blk a:hover{text-decoration:underline;}
.soc_links_bottom{margin-top:20px;}
body .css-13cymwt-control, body .css-t3ipsp-control{min-height:46px;}
.related-blog .row>div.col-md-6{display:flex;}
.blog_details_page .blog-bottom-content a.white_btn{color:#b10d28;margin-top:20px;display:inline-block;}
.blog_details_page .blog-bottom-content a.white_btn:hover{color:#fff;}
/*Blog details page end*/
.quote_btn_wrap a{margin-right:15px;}
/*Hero section box start*/
.hero_blk .hero_box_outer{border: 1px solid #d85f73;padding: 10px;max-width: 50%;margin:0;border-radius: 15px;}
.hero_blk .hero_box {padding: 30px 40px 40px 40px;border-radius: 15px;background: rgba(177, 13, 40, 0.8);text-align:left;}
.hero_blk .hero_box h1{font-size: 34px;line-height: 40px;color: #fff;margin-bottom:0;}
.hero_blk .hero_box p {font-size: 18px;color:#fff;margin:15px 0;}
.hero_box a{display:inline-block;}
.hero_box .white_btn:hover > svg path{fill:#fff;}
/*Hero section box end*/
/*For business overview start*/
.business_overview_hero{background: url("../public/img/For-Business-Main.jpg") no-repeat center center/cover;padding: 100px 15px 50px 15px;position: relative;}
.hero_box .white_btn:hover{border-color: #fff;}
.for_business_overview .subscribe_blk, .img_txt_blk_grey{background: #f2f2f2;}
.replace_system_content{padding-right: 50px;}
.img_txt_blk_grey .replace_system_content{padding-left: 50px;}
/*For business overview end*/
/*My Account Login Popup start*/
.account_popup_inr, .inner_content_wrapper .inner_content, .account_content_bottom .badges{display: flex;}
.account_img_blk {width: calc(40% - 20px);margin-right: 20px;}
.account_content_blk {width: 60%;}
.inner_content .account_content_box {background: #feeceb;margin-right: 20px;padding: 20px;border-radius:0 25px;}
.inner_content .account_content_box:last-child{margin-right:0;}
.account_content_bottom {background: #fff;padding:0;margin-top: 20px;border-radius:0 25px;}
.badge_wrapper a img {max-height: 40px;}
.account_content_bottom .badges .badge_wrapper {margin-right: 10px;}
.account_content_bottom p {margin-bottom: 10px;}
.account_img_blk .image_wrapper {height: 100%;display: flex;border-radius: 0 25px 25px 25px;overflow: hidden;}
.modal-header {background: #b10d28;color: #fff;border-top-left-radius: 10px;border-top-right-radius:10px;padding:8px 20px;}
.modal-header .close .sr-only {display: none;}
.modal-header .close {width: 30px;height: 30px;display: inline-flex;justify-content: center;align-items: center;font-size: 24px;border-radius: 200px;border: none;outline: none;background: #fff;}
.modal-header .close:hover{background: #2b2b2b;color:#fff;}
.modal-content{border-radius: 10px;}
/*My Account Login Popup end*/
/*Thank you page start*/
.thank_you_blk{padding:50px;background:#f2f2f2;}
.thank_you_content{text-align:center;padding:40px;background:#fff;max-width:850px;margin:0 auto;border-radius:20px;}
.thank_you_content h1{font-size:34px;margin-bottom:30px;}
.thank_you_content img{max-width:400px;margin-bottom:30px;}
.thank_you_content p:last-child{margin-bottom:0;}
.thank_you_content p a{font-weight:500;}
/*Thank you page end*/
.cust_review_btn{text-align:center;margin-top:30px;}
.cust_review_btn a{display:inline-block;}
/*New menu changes*/
.menu_wrap {position: fixed;top: 0;right: -400px;background: #fff;height: 100%;padding:60px 15px 15px 15px;width: 400px;
-webkit-transition: all .5s ease 0s;-moz-transition: all .5s ease 0s;-o-transition: all .5s ease 0s;transition: all .5s ease 0s;box-shadow: 0px 0px 15px 10px rgb(0 0 0 / 5%);}
.menu_wrap.open_right_menu {right: 0;}
.menu_logo_wrap {text-align: center;}
.menu_logo_wrap img {max-width: 250px;}
.menu_phone_wrap {text-align: center;margin: 30px 0;}
.menu_phone_wrap a {font-size: 22px;font-weight: 500;color: #2b2b2b;text-decoration: none;}
.menu_phone_wrap a:before {content: '';background: url('../public/img/menu-ph-icon.png') no-repeat;padding-left: 45px;}
.menu_item_wrap {height: calc(100vh - 250px);}
.menu_item_inner {max-width: 100%;margin: 0 auto;padding: 0 40px 0 40px;}
.menu_item_inner ul {list-style: none;padding: 0;margin: 0;}
.menu_item_inner ul li {padding: 15px 0;border-bottom: 1px solid #ccc;}
.menu_item_inner ul > li.menu-item-1 {position: relative;z-index: 1;cursor: pointer;}
.menu_item_inner ul li>a {color: #2b2b2b;font-size: 18px;position: relative;display: block;z-index: 9;text-decoration: none;}
.menu_item_inner ul > li.menu-item-1 > a {display: block;}
.menu_item_inner .toggle-icon {cursor: pointer;display: inline-block;content: "";border: none;position: absolute;top: 0;
right: 0;background: url('../public/img/arrow-grey.png') no-repeat 100% 50%;width: 50px;height: 58px;}
.menu_item_inner ul li>ul {border-top: 1px solid #ccc;margin-top: 20px;padding-top: 20px;}
.menu_item_inner ul li ul li {padding: 5px 0;border-bottom: none;}
.menu_item_inner ul li>a {color: #2b2b2b;font-size: 18px;position: relative;display: block;z-index: 9;}
.menu_item_inner ul li ul li>a {font-size: 16px;}
.menu_item_inner ul li.oil_sale>a {color: #ee3b33;}
#mobile_nav, .mobile_menu_toggle, .my_acc_mobile {display: none;}
.header:not(.landing_header) {position: fixed;top: 0;left: 0;width: 100%;padding: 10px 0px;background-color: transparent;background: linear-gradient(rgba(0, 0, 0, .5),30%,rgba(0, 0, 0, 0));box-shadow: inset 0 0 0 1px rgba(233, 233, 233, 0);z-index: 99;
-webkit-transition: background .5s ease-in-out;-moz-transition: background .5s ease-in-out;-o-transition: background .5s ease-in-out;transition: background .5s ease-in-out;}
.hamburger_menu {outline: none;border: none;background: transparent;cursor: pointer;width: 40px;margin-top: 5px;}
.toggle_menu_btn {position: relative;z-index: 9;}
.header_nav>ul{margin:0;display:flex;align-items:center;}
.header_nav>ul>li {list-style-type: none;display: inline-block;text-align: center;}
.header_nav>ul>li>a {text-decoration: none;color: #2b2b2b;padding: 0 25px;display: block;}
.header_nav>ul>li:not(.cir_btn):not(.toggle_menu_btn):hover {background: #b10d28;}
.header_nav>ul>li:not(.cir_btn):hover>a {color: #fff;}
.header_nav ul li.ph_number, .header_nav ul li.my_acc_btn, .toggle_menu_btn{display:flex;align-items:center;margin-left:20px;}
.header_nav ul li.ph_number a, .header_nav ul li.my_acc_btn a, .header_nav ul li.my_acc_btn button{width: 40px;height: 40px;border: 1px solid #fff;border-radius: 200px;padding: 0;}
.stickyHeader .header_nav ul li.ph_number a, .stickyHeader .header_nav ul li.my_acc_btn a, .stickyHeader .header_nav ul li.my_acc_btn button{border-color:#b10d28;}
.header_nav ul li.ph_number a{background: url('../public/img/telephone_icon_white.svg') no-repeat center;background-size:20px;}
.header_nav ul li.my_acc_btn a, .header_nav ul li.my_acc_btn button{background: url('../public/img/user_icon_white.svg') no-repeat center;background-size:20px;}
.header_nav ul li.ph_number a:hover{background:#fff url('../public/img/telephone_icon.svg') no-repeat center;background-size:20px;}
.header_nav ul li.my_acc_btn a:hover, .header_nav ul li.my_acc_btn button:hover{background:#fff url('../public/img/user_icon.svg') no-repeat center;background-size:20px;}
.stickyHeader .header_nav ul li.ph_number a{background: url('../public/img/telephone_icon.svg') no-repeat center;background-size:20px;}
.stickyHeader .header_nav ul li.ph_number a:hover{background:#b10d28 url('../public/img/telephone_icon_white.svg') no-repeat center;background-size:20px;}
.stickyHeader .header_nav ul li.my_acc_btn a, .stickyHeader .header_nav ul li.my_acc_btn button{background: url('../public/img/user_icon.svg') no-repeat center;background-size:20px;}
.stickyHeader .header_nav ul li.my_acc_btn a:hover, .stickyHeader .header_nav ul li.my_acc_btn button:hover{background:#b10d28 url('../public/img/user_icon_white.svg') no-repeat center;background-size:20px;}
.header_nav ul.sub-menu {padding-left: 0;text-align: left;position: absolute;left: 0;top: 100%;display: inline-block;min-width: 250px;background: #fff;padding:0;z-index: 3;visibility: hidden;opacity: 0;border: 0;-webkit-transform: scaleY(0);-ms-transform: scaleY(0);transform: scaleY(0);-webkit-transform-origin: top center;-ms-transform-origin: top center;transform-origin: top center;-webkit-transition: all 0.4s ease 0s;transition: all 0.4s ease 0s;}
.header_nav ul.sub-menu.small_sub_menu{min-width:200px;}
.header_nav>ul>li:hover > ul.sub-menu{visibility: visible;opacity: 1;-webkit-transform: scaleY(1);-ms-transform: scaleY(1);transform: scaleY(1);z-index: 9;}
.header_nav ul.sub-menu li {margin-top: 0;border-bottom: 1px solid #dfdfdfde;padding: 12px 20px;}
.header_nav ul.sub-menu li:last-child{border-bottom:none;}
.header_nav ul.sub-menu li:hover {background: #b10d28;border-bottom: 1px solid #b10d28;color: #fff;}
.header_nav ul.sub-menu li:hover>a {color: #fff;}
.header_nav ul ul.sub-menu .quote_btn {margin-left: 20px;}
.header_nav ul.sub-menu li:not(.quote_btn) a {padding: 0;display: block;}
.hamburger_menu .close_icon, .hamburger_menu.open_menu .menu_icon {display: none;}
.hamburger_menu.open_menu .close_icon, .hamburger_menu .menu_icon {display: block;}
.cart_icon img, .logo_wrap img, .hamburger_menu .menu_icon {filter: brightness(100);-webkit-transition: filter .5s ease-in-out;-moz-transition: filter .5s ease-in-out;-o-transition: filter .5s ease-in-out;transition: filter .5s ease-in-out;}
header.header.stickyHeader {background: #fff;-webkit-transition: background .5s ease-in-out;-moz-transition: background .5s ease-in-out;-o-transition: background .5s ease-in-out;transition: background .5s ease-in-out;box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);}
.stickyHeader .logo_wrap img, .stickyHeader .hamburger_menu .menu_icon{filter: brightness(1);-webkit-transition: filter .5s ease-in-out;-moz-transition: filter .5s ease-in-out;-o-transition: filter .5s ease-in-out;transition: filter .5s ease-in-out;}
.menu_item_inner ul li a:hover {text-decoration: none;color:#b10d28;}
.menu_item_inner ul > li:hover .toggle-icon {background-image: url('../public/img/arrow-red.png');}
.menu_item_inner ul li > a.openMenu {color:#b10d28;}
.menu_item_inner .openMenu+.toggle-icon {background: url('../public/img/arrow-red.png') no-repeat 100% 50%;}
.menu_item_inner ul > li.menu-item-1>div{margin-top:15px;padding-top:15px;border-top:1px solid #ccc;}
.landing_header .logo_wrap img{filter:brightness(1);}
.landing_header .header_nav ul li.ph_number a{background: url('../public/img/telephone_icon.svg') no-repeat center;background-size:20px;}
.landing_header .header_nav>ul>li:not(.cir_btn):not(.toggle_menu_btn):hover {background:transparent;}
.landing_header .header_nav ul li.ph_number a:hover{background:#b10d28 url('../public/img/telephone_icon_white.svg') no-repeat center;background-size:20px;}
.landing_header.stickyHeader+main{padding-top:80px;}
/*Error message start*/
.error_msg_blk{background:#b10d28;text-align:center;padding:10px 15px;position:relative;}
.error_msg_blk p{margin-bottom:0;color:#fff;max-width:calc(100% - 50px);}
.close_btn_wrap{position:absolute;right:20px;top:50%;transform:translateY(-50%);background:#fff;width:30px;height:30px;border-radius:100px;color:#b10d28;font-weight:600;display:flex;justify-content:center;align-items:center;cursor:pointer;}
.close_btn_wrap:hover{background:#000;color:#fff;}
.error_msg_blk+header.header.stickyHeader {top:0 !important;}
/*Error message end*/
/*Compare oil start*/
.news-page-header {border-bottom: 1px solid #dedede;padding-bottom: 20px;margin-bottom: 20px;}
.news-page-header h2 {font-size: 34px;}
.news-page-header .author_name_row {color: #888;font-weight: 600;}
.news-page-header .author_name_row .post_date {font-style: italic;}
.no_header_footer .landing_header, .no_header_footer .footer {display: none;}
.compare_oil_blk {padding: 100px 0;}
.no_header_footer .landing_header.stickyHeader+main {padding-top:0;}
.sidebar_top_pic {border: 1px solid #ddd;border-radius:10px;padding: 10px;margin-bottom: 30px;}
.news_sidebar_list {list-style: none;padding: 0;margin: 0;}
.news_sidebar_list li {padding-left: 30px;background: url('../public/img/bullet-img.svg') no-repeat 0 5px/18px 18px;margin-bottom: 15px;}
.news_sidebar_list li:last-child{margin-bottom:0;}
.btn-sidebar {text-align:center;display:block;margin-top:20px;}
.news_Mrk_text_content h3{margin-top:25px;font-size:24px;}
.news_content_area ul{padding-left:20px;}
.compare_oil_blk .row>div:first-child{padding-right:80px;}
.news_content_area h3{font-size:24px;margin:15px 0 5px 0;}
.news_content_area img{margin:15px 0;}
/*Compare oil end*/
.privacy_note{margin-top:0;margin-bottom:15px;}
.privacy_note .form-check-label, .claim_form_blk .privacy_note_txt p{color:#929395;font-size:12px;text-align:left;}
.contact-form-blk form .privacy_note .form-check-label{margin:0 0 0 5px;font-weight:normal;}
.privacy_note_txt{margin-bottom:15px;}
.claim_form_blk .privacy_note_txt p a{color:#0d6efd;}
.claim_form_blk .privacy_note_txt p a:hover{color:#0a58ca;}
@media (max-height:770px) {
    .menu_item_wrap{max-height:450px;overflow:hidden;overflow-y: auto;}
}

@media (min-width: 1921px) {
    .home_security-camera_page .hero_blk {background: url("../public/img/Remote-App-Main-Header-1.jpg") no-repeat right center/cover;}
}
@media (min-width: 1400px) {
    .license .terms_content_blk .container{max-width:800px;}
    .pricing-alm-blk .container{max-width:1024px;}
    .blog_details_page .blog-content-inr.container {max-width: 1000px;}
}
@media (min-width:768px) {
    body{overflow-x:hidden;}
    .footer .footer_menu_title svg {display: none;}
    .stickyHeader {top: 0px;-webkit-animation: topMenuSlideDown .5s ease-in-out;-moz-animation: topMenuSlideDown .5s ease-in-out;-ms-animation: topMenuSlideDown .5s ease-in-out;-o-animation: topMenuSlideDown .5s ease-in-out;animation: topMenuSlideDown .5s ease-in-out;}
    .footer .footer_menu {display: block !important;}
    .mobile_app_blk {display: none;}
    .box_services span.box_services_icon:hover {box-shadow: 0px 0px 13px #ef3b3280;outline: 1px;}
    .home_mob-automation-tabs-blk #v-pills-tab {flex-wrap: wrap;flex-direction: column !important;}
    .home_automation_page .subscribe_blk {margin:0 !important;}
    .home_security-camera_manage_img video {width: 100%;}
    .home_mob-security-camera-tabs-blk #v-pills-tab {flex-wrap: wrap;flex-direction: column !important;}
    .home_mob-security-camera-tabs-blk .home_mob-tab {display: flex !important;flex-direction: column-reverse;}
    .home_security-camera_page .home_security-camera-cards-img1, .home_security-camera_page .home_security-camera-cards-img2 {padding: 80px 42px 30px 42px;}
    .home_security-camera_page .home_security-camera-cards h4 {font-size: 24px;}
    .home_security-camera_manage_content {padding-right: 50px;}
}
@media (max-width: 1080px) {
    .home_mob-automation-tabs-blk #v-pills-tab {width: 100% !important;flex-wrap: wrap;flex-direction: column !important;}
    .home_mob-automation-tabs-blk .home_mob-tab {display: flex !important;flex-direction: column-reverse;}
    .home_automation_page .home_automation-tabs-blk {padding: 60px 40px;}
    .home_automation_page .home_automation-cards-img1, .home_automation_page .home_automation-cards-img2 {padding: 80px 42px 30px 42px;}
    .home_automation_page .home_automation-cards h4 {font-size: 24px;}
    .home_automation_page .subscribe_blk {margin:0 !important;}
    .home_security-camera_manage_img video {width: 100%;}
    .home_mob-security-camera-tabs-blk #v-pills-tab {width: 100% !important;flex-wrap: wrap;flex-direction: column !important;}
    .home_mob-security-camera-tabs-blk .home_mob-tab {display: flex !important;flex-direction: column-reverse;}    
    .home_security-camera_page .home_security-camera-cards-img1, .home_security-camera_page .home_security-camera-cards-img2 {padding: 80px 42px 30px 42px;}
    .home_security-camera_page .home_security-camera-cards h4 {font-size: 24px;}
    .home_security-camera_page .subscribe_blk {margin:0 !important;padding: 40px 20px;}
    .home_professional-monitoring_page .dedicated-staff_blk, .home_professional-monitoring_page .pricing-alm-blk {
    padding: 50px 20px;}
    .home_professional-monitoring_page .slomins-professional-card, .home_professional-monitoring_page .request-for-quote {padding: 20px;}
    .home_professional-monitoring_page .dedicated-staff_blk,.home_professional-monitoring_page .pricing-alm-blk {
    padding: 50px 20px;}
    .home_professional-monitoring_page .slomins-professional-card,.home_professional-monitoring_page .request-for-quote{padding: 20px;}
    .why-choose-the-slomins-shield .hero_blk h1, .why-choose-the-slomins-shield .hero_blk .hero_inr p {width: 100%;}
    .get_slomin_shield_blk {padding: 60px 20px;}
}
@media (max-width:1399px) {
    .header_nav ul li.login_signup_btn a {padding: 10px 15px;}
    .home_security_products-tabs-blk .nav-link svg{display:none;}
    .header_nav>ul>li>a{padding: 0 20px;}
    .company_history_blk .nav-pills .nav-link{margin-bottom:15px;}
    .loc_img_box .white_btn {font-size: 12px;}
}
@media (max-width:1199px) {
    .header_nav ul li a {font-size: 13px;padding: 0 15px;}
    .header_nav ul li.login_signup_btn a {padding: 10px;font-size: 13px;}
    .hero_blk .hero_box_outer{max-width: 80%;margin: 0 auto;}
    .home_automation-tabs-blk .nav-link{padding: 15px 10px;font-size: 14px;}
    .home_mob-automation-tabs-blk .tab-content {width: 100%;}
    .home_mob-automation-tabs-blk .tab-pane{text-align:center;}
    .home-slomins-support-inr ._slm-item{padding:15px 10px;}
    .blog_tab-blk .nav-link {border-radius: 30px;padding: 15px 10px;font-size: 12px;}
    .loc_img_box .white_btn {font-size:16px;}
    figure.loc_img_effect h2{padding-top:40%;font-size:24px;}
}
@media (max-width:992px) {
    .account_img_blk, .menu_desktop, .customer_review_blk .sec_title h2 br {display: none;}
    .mobile_menu_toggle {display: block;}
    #mobile_nav {position: absolute;width: 100%;height: 100%;background: #fff;z-index: 5;display: none;height: calc(100vh - 60px);padding-top: 20px;max-height: 100vh;overflow: hidden;overflow-y: hidden;overflow-y: auto;}
    .logo_toggle_wrap {display: flex;justify-content: space-between;align-items: center;}
    .mobile_menu_toggle {display: flex;justify-content: center;align-items: center;height:15px;width: 20px;position: relative;cursor: pointer;}
    .mobile_menu_toggle span {display: block;position: absolute;height: 2px;width: 20px;background-color: #b10d28;border-radius: 1px;opacity: 1;-webkit-transform: rotate(0deg);transform: rotate(0deg);}
    .mobile_menu_toggle span:first-child {top: 0;}
    .mobile_menu_toggle span:nth-child(2), .mobile_menu_toggle span:nth-child(3) {top: 7px;width: 15px;}
    .mobile_menu_toggle span:nth-child(4) {top: 14px;}
    .mobile_menu_toggle.open span:nth-child(1), .mobile_menu_toggle.open span:nth-child(4) {opacity: 0;}
    .mobile_menu_toggle.open span:nth-child(2) {-webkit-transform: rotate(45deg);transform: rotate(45deg);width: 20px;}
    .mobile_menu_toggle.open span:nth-child(3) {-webkit-transform: rotate(-45deg);transform: rotate(-45deg);width: 20px;}
    .mob_main_menu_blk {padding: 0 15px;}
    #mobile_nav .overlay_content {background: #fff;}
    .mob_main_menu_blk ul {margin-bottom: 0;padding-left: 0;list-style: none;}
    .mob_main_menu_blk ul li a {display: inline-block;width: calc(100% - 20px);text-decoration: none;color: #2b2b2b;}
    .mob_main_menu_blk ul li button {width: 20px;border: 0;padding: 0;cursor: pointer;float: right;background: none;}
    .mob_main_menu_blk ul li button svg {-webkit-transform: rotate(0deg);transform: rotate(0deg);-webkit-transition: all .25s;transition: all .25s;}
    .mob_main_menu_blk ul li button.open_sub svg {-webkit-transform: rotate(180deg);transform: rotate(180deg);}
    .mob_main_menu_blk ul li button.open_sub svg path {fill: #B10D28;}
    .mob_main_menu_blk ul {margin-bottom: 0;padding-left: 0;list-style: none;}
    .sub_menu_wrap {display: none;background: #f2f2f2;padding: 5px 15px;margin-top: 5px;}
    .mob_main_menu_blk ul.mob_menu>li {padding: 10px 0;border-bottom: 1px solid rgba(43, 26, 43, .1);}
    .mob_main_menu_blk ul.mob_menu>li.active_menu>a {color: #B10D28;}
    .sub_menu_wrap .mob_sub_menu li {margin: 10px 0;}
    .nav_bottom_content {text-align: center;padding: 20px 0;}
    .nav_bottom_content a {color: #2b2b2b;font-weight: 600;text-decoration: none;}
    .nav_bottom_content a svg {margin-right: 10px;}
    .hero_inr h1 br, .hero_inr p br, .reason_blk_white .installation_reason_title h2 br, .reason_blk_white .installation_reason_title p br, .save_money_content h2 br, .save_money_content p br, .home_security_products-tabs-blk .nav-link svg{display:none;}
    .home_security_products-tabs-blk .card .card-content {padding: 15px;}
    .home_security_products-tabs-blk .card{border-radius: 0 35px;}
    .home_automation-tabs-blk .nav-link {padding: 15px 5px;font-size: 12px;}
    .home_automation-tabs-blk .nav-pills .nav-item {margin: 0 2px;width: auto;}
    .header .my_acc_mobile{margin-left:auto;margin-right: 20px;}
    .header .my_acc_mobile button{width:30px;height:30px;border:1px solid #b10d28;border-radius:200px;padding:0;background:url('../public/img/user_icon.svg') no-repeat center;background-size:14px;}
    .account_content_blk {width: 100%;}
    .commitment_box{margin-bottom:20px;}
    .blog_tab-blk .nav-link {padding:15px 0px;font-size:12px;}
    .blog_details_page .hero_content h1{max-width: 100%;}
    .related-blog .card{margin-bottom: 20px;}

    figure.loc_img_effect h2{padding-top:30%;}
    figure.loc_img_effect p{padding:10px;}
    .loc_img_box figure figcaption{padding:30px;}
    .contact-form-blk .contact-form-row{max-width:100%;padding:20px 0;}
    .contact-box-blk .row>div .box-feature{margin-bottom:25px;}
    .contact-box-blk .row>div:last-child .box-feature{margin-bottom:0;}
    .compare_oil_blk .row>div:first-child{padding-right:30px;}
}
@media (max-width:767px) {
    .footer .footer_menu {display: none;border-top: 1px solid #353c42;margin-top: 10px;}
    .footer_menu_title {display: flex;align-items: center;justify-content: space-between;cursor: pointer;}
    .footer_menu_title h3 {margin-bottom: 0;font-size: 18px;}
    .footer_menu_blk {margin-top: 10px;background: #1b1f22;padding: 10px 20px;border-radius: 10px;}
    .footer_menu_title svg {background-color: #fff;border-radius: 30px;padding: 5px;width: 20px;height: 20px;-webkit-transition: all .25s;transition: all .25s;cursor: pointer;}
    .open_footer .footer_menu_title svg {-webkit-transform: rotate(45deg);transform: rotate(45deg);}
    .app_store_blk {display: none;}
    .footer_logo {margin-left: 0;text-align: center;margin-bottom: 20px;}
    .footer .footer_menu li a {font-size: 14px;}
    .mobile_app_blk .app_store_blk {display: block;}
    .mobile_app_blk .app_store_blk ul {margin-top: 20px;margin-bottom: 0;padding-left: 0;justify-content: center;}
    .mobile_app_blk .app_store_blk ul li {margin: 0 10px;}
    .footer {padding: 40px 0;}
    .hero_blk {padding:100px 10px 50px 10px;background-position: center top;background-size: cover;}
    .hero_inr h1, .hero_blk .hero_box h1, .faq_blk .sec_title h1{font-size:26px;line-height:34px;text-align: center;}
    .hero_inr p, .hero_blk .hero_box p{font-size: 16px;margin: 15px 0;text-align: center;}
    .installation_reason_blk, .replace_system_blk, .customer_reviews_blk, .customer_service_blk, .subscribe_blk {padding:30px 0;}
    .installation_reason_title h2, .sec_title, .subscribe_inr h2, .replace_system_img {margin-bottom: 20px;font-size: 24px;}
    .inst_reason_box {margin-bottom: 15px;}
    .hero_content {text-align: center;}
    .replace_system_inr>.row>.col-12:first-child {order: 2;}
    .replace_system_content {padding-right: 0;}
    .img_txt_blk_grey .replace_system_content {padding-left: 0;}
    .appointment_service, .location-page .button_hero-blk {flex-direction: column;}
    .appointment_service .service_blk {width: 100%;}
    .appointment_service .service_blk:first-child {border-right: none;margin-bottom: 20px;}
    .subscribe_btn {margin-top:0;}
    .areaInterest_box_logo .header_logo img {max-width: 160px;}
    .areainterest_content .areaInterest_box .textlarge {margin: 10px 0;}
    p.textimg img {max-width: 12px;}
    .box_services span.box_services_icon {background: rgb(0 0 0 / 0%);box-shadow: none;border: 0px solid #ececec;display: flex;padding: 15px;}
    .box_services h4.bar {width: 100%;border: 0px solid #ffaa4d;font-size: 22px;line-height: 36px;padding: 0px;}
    .service_box_wrap{margin:0;}
    .box_services .grid_center, .timeline_wrap {flex-direction: column;}
    .box_services {max-width: 300px;}
    .page_returning_content .returningCust_box .retcustxt {font: 400 17px/30px Poppins;color: #000;margin: 10% auto 5%;}
    .landing_footer .copyright_blk {order: 3;}
    .landing_footer .legal_menu_wrap {order: 2;margin: 10px 0;}
    .landing_footer .footer_social_link_wrap {order: 1;}
    .legal_menu_wrap ul li:last-child, .footer_social_link_wrap ul li:last-child {margin-right: 0;}
    .save_money_blk {padding-bottom: 50px;}
    .quote_form .input-group {margin-bottom: 10px;}
    body .home_security_products_page .hero_blk {padding:80px 10px 20px 10px;}
    .home_security_products-tabs-blk {padding:15px 0;}
    .home_security_products-tabs-blk .home_security-inr-sec {flex-direction: column;}
    #v-pills-tab {flex-direction: row !important;flex-wrap: nowrap;overflow-x: auto;max-width: 100%;}
    .home_security_products-tabs-blk .nav-link {margin:5px 0px;padding: 10px 15px;}
    .home_security_products-tabs-blk .tab-pane h2 {margin: 20px 0px;font-size: 24px;}
    .home_security_products-tabs-blk .card {margin: 0px 0px 10px 0;border-radius: 0 35px;}
    .home_security_products_page .hero_inr h1 {font-size: 24px;line-height: 28px;margin: 20px 0px 0px 0px;}
    .home_security_products_page .hero_inr p {margin: 2px 0px 40px 0px;}
    .home_security_products-tabs-blk .nav-pills .nav-item {width: calc(100% - 10px);margin: 0 5px;}
    .home_security_products-tabs-blk .card h4, .blog_tab-blk .card h4{font-size: 18px;line-height: normal;}
    .get_slomin_shield_blk {padding: 25px 0px;}
    .get_slomin_shield_content {padding: 40px 0px;}
    .get_slomin_shield_blk .get_slomin_shield_content h2, .join_family_content h2{font-size: 24px;}
    .get_slomin_shield_blk .get_slomin_shield_content p, .blog_details_page .blog-bottom-content>div{font-size: 14px;}
    .get_slomin_shield_blk .white_btn, .get_slomin_shield_blk .red_btn {display: block;text-align: center;margin: 20px 0px;}
    .subscribe_blk .subscribe_inr form {width: 100%;}
    body .home_security_product-detail .hero_blk {padding: 20px 10px;}
    .home_security_products-detail .hero_inr h1 {font-size: 26px;}
    .home_security_products-detail .hero_inr p {font-size: 14px;}
    .home_security_products-detail .hero_content {margin:20px 0px 0 0px;}
    .home_security_product-detail .hero_inr h1 {font-size: 24px;line-height: 28px;margin: 20px 0px 0px 0px;}
    .home_security_product-detail .hero_inr p {margin: 2px 0px 40px 0px;}
    .home_security_products-tabs-blk .nav-pills{flex-wrap: wrap;border-radius: 0;}
    .contact-box-blk, .contact-form-blk{padding: 30px 0px;}
    .key-features_blk{padding:30px 0px 15px 0;}
    .key-features_title h2, .get_slomin_shield-detial_blk .get_slomin_shield_content h2, .contact-form-blk h3{font-size: 24px;}
    .key-features_subtitle p {font-size: 14px;text-align: center;max-width: 100%;}
    .get_slomin_shield-detial_blk {padding: 25px 10px;overflow: hidden;}
    .get_slomin_shield-detial_blk .get_slomin_shield_content {padding:20px 0 0 0;}
    .get_slomin_shield-detial_blk .get_slomin_shield_content p {font-size: 14px;}
    .get_slomin_shield-detial_blk .white_btn, .get_slomin_shield-detial_blk .red_btn {display: block;text-align: center;}
    body .get_slomin_shield-detial_blk .red_btn{margin-right:10px;}
    .subscribe_blk .subscribe_inr form {width:auto;}
    .home_automation-tabs-blk, .home_mob-automation-tabs-blk, .home_automation_security-camera, .faq_blk{padding: 30px 0px;}
    .home_automation_security-camera_content {padding:0;}
    .home_automation-cards-img1, .home_automation-cards-img2 {padding:30px 15px !important;}
    .home_mob-automation-tabs-blk #v-pills-tab {width: 100% !important;flex-wrap: wrap;flex-direction: column !important;}
    .home_mob-automation-tabs-blk .home_mob-tab {display: flex !important;flex-direction: column-reverse;}
    .home_automation_security-camera_content h2, .home_automation-cards h4, .home_automation-tab-inr h2, .home-slomins-support .section_heading h2 {font-size: 24px !important;}
    .home_automation_security-camera_content p, .home_automation-cards p, .home_automation-tab-inr p {font-size: 16px !important;}
    .home-slomins-support-inr ._slm-item h4 {font-size: 16px;margin: 8px;}
    .home-slomins-support-inr ._slm-item p, .home_automation_page .home_mob-automation-tabs-blk p {font-size: 16px !important;}
    .home_automation_page .home_mob-automation-tabs-blk .nav-pills .nav-link {font-size: 16px;}
    .home_automation_page .subscribe_inr h2 {font-size: 20px;}
    .home_automation_page .subscribe_blk {margin:0 !important;}
    .home_automation_page .home-slomins-support-inr.text-center ._slm-item {padding: 30px;}
    .home_security-camera_manage{padding:20px 0 30px 0;}
    .home_automation-tabs-blk .nav-pills{flex-wrap:wrap;}
    .home_security-camera_page .home_security-camera_manage_content h2, .home_security-camera_page .home_security-camera_manage_content p {margin: 15px 0px;text-align: center;}
    .home_security-camera_manage_content {padding:0;}
    .home_security_blk .home_security-camera_manage_content{padding:0;}
    .home_security-camera-cards-img1, .home_security-camera-cards-img2 {padding: 109px 28px 100px 28px !important;}
    .home_mob-security-camera-tabs-blk #v-pills-tab {width: 100% !important;flex-wrap: wrap;flex-direction: column !important;}
    .home_mob-security-camera-tabs-blk .home_mob-tab {display: flex !important;flex-direction: column-reverse;}
    .home_security-camera-cards .row {gap: 15px;}
    .home_mob-security-camera-tabs-blk .home_security-camera-tab-inr {padding: 35px 25px;}
    .home_security-camera_manage_content h2, .home_security-camera-cards h4, .home_security-camera-tab-inr h2, .home-slomins-support .section_heading h2 {font-size: 24px !important;}
    .home_security-camera_manage_content p, .home_security-camera-cards p, .home_security-camera-tab-inr p {font-size: 16px !important;}
    .home-slomins-support-inr ._slm-item h4 {font-size: 18px !important;margin:5px 0 !important;}
    .home_security-camera_page .home-slomins-support-inr.text-center ._slm-item {padding: 15px;}
    .home_security-camera_page .home-slomins-support {padding:30px 0;}
    .home_security-camera_page .home-slomins-support-inr ._slm-item p, .home_security-camera_page .home_mob-security-camera-tabs-blk p {font-size: 16px !important;}
    .home_security-camera_page .home_mob-security-camera-tabs-blk .nav-pills .nav-link {font-size: 16px;}
    .home_security-camera_page .subscribe_blk {margin:0 !important;}
    .home_security-camera_page .home-slomins-support-inr.text-center ._slm-item {padding: 30px;}
    .home_security-camera_manage_img video {width: 100%;}
    .home_professional-monitoring_page .subscribe_blk {margin:0 !important;}
    .home_professional-monitoring_page .home-slomins-support-inr.text-center ._slm-item {padding: 20px;}
    .home_professional-monitoring_page .hero_blk, .contact-page .hero_blk, .get_quote_pg .hero_blk, .blog_details_page .hero_blk, .privacy-policy .hero_blk, .faq_page .hero_blk {padding:100px 10px 50px 10px;}
    .home_professional-monitoring_page .got_covered_blk, .home_professional-monitoring_page .dedicated-staff_blk, .home_professional-monitoring_page .pricing-alm-blk, .related-blog{padding:30px 0;}
    .home_professional-monitoring_page .got_covered_blk .got_covered_content h2, .home_professional-monitoring_page .dedicated-staff_blk .dedicated-staff_content h2, .home_professional-monitoring_page .customer_reviews_blk h2, .home-slomins-support .section_heading h2, .pricing-alm-blk h2 {font-size: 24px !important;}
    .home_professional-monitoring_page .pricing-alm-blk .card-body, .terms_content{padding:20px;}
    .home_professional-monitoring_page .pricing-alm-blk .pricing-column {margin: 0px 0px 20px;}
    .why-choose-the-slomins-shield .hero_blk{padding:100px 10px 50px 10px;}
    .why-choose-the-slomins-shield .hero_blk h1, .why-choose-the-slomins-shield .hero_blk .hero_inr p{width: 100%;}
    .why-choose-the-slomins-shield .trust-high-security_blk .trust_high_security_content h2, .why-choose-the-slomins-shield .trust-high-security_blk .trust-high-security_heading h2 {font-size: 24px;margin-bottom: 0px;}
    .why-choose-the-slomins-shield .trust-high-security_blk .trust-high-security_heading h2{margin-bottom:20px;}
    .why-choose-the-slomins-shield .trust-high-security_blk .trust_high_security_content h2 {font-size: 24px;margin: 10px 0px;}
    .why-choose-the-slomins-shield .trust-high-security_blk .trust_high_security_content p{margin-bottom: 10px;}
    .why-choose-the-slomins-shield .customer_reviews_blk h2, .faq_box .faq_sec_title h3{font-size: 24px;}
    .home-slomins-support .section_heading h2, .location-details-blk .container h2 {font-size: 24px;}
    .home-slomins-support-inr.text-center ._slm-item {padding:20px 10px;}
    .get_slomin_shield_blk, .home_professional-monitoring_page .slomins-professional, .why-choose-the-slomins-shield .trust-high-security_blk{padding:30px 0;}
    .get_slomin_shield_content {padding:30px 0 0 0;}
    .get_slomin_shield_blk .get_slomin_shield_content h2, .trust_high_security_content h3, .commitment_blk .sec_title h2{font-size: 24px;}
    .get_slomin_shield_blk .get_slomin_shield_content p {font-size: 14px;}
    .get_slomin_shield_blk .white_btn, .get_slomin_shield_blk .red_btn {display: block;text-align: center;margin: 20px 0px;}
    .legal_menu_wrap {margin: 10px 0;}
    .commitment_box{margin-bottom: 15px;}
    .company_history_blk .nav-pills .nav-link{min-width: inherit;}
    .company_history_blk .nav-pills .nav-item {width: calc(25% - 10px);margin: 5px;}
    .company_history_blk .nav-pills .nav-item .nav-link {width: 100%;margin: 0;}
    .timeline_img {width: 100%;margin-right: 0;margin-bottom: 15px;}
    .timeline_txt {width: 100%;}
    .company_history_blk .tab-content {padding-top: 15px;}
    .service_info_box {text-align: center;display: flex;flex-direction: column;justify-content: center;align-items: center;margin-bottom: 15px;}
    .service_info_blk .row>div:last-child .service_info_box{margin-bottom:0;}
    .customer_img {margin-bottom: 20px;}
    .customer_review_content {padding-left: 0;}
    .customer_review_black .customer_review_content {padding-right: 0;}
    .customer_review_black .row > div:nth-child(1) {order: 2;}
    .customer_review_black .row > div:nth-child(2) {order: 1;}
    .customer_review_black .customer_img{padding:0;}
    .customer_review_black .customer_img::after{display:none;}
    .contact-page .hero_blk {padding:100px 10px 50px 10px;}
    .blog_tab-blk{padding-top:30px;padding-bottom:30px;margin-bottom: 30px;}
    .blog_page .blog_tab-blk .tab-content .col-md-8{order: 2;}
    .blog_page .subscribe_blk {margin: 0px 10px 50px 10px !important;}
    .blog_page .subscribe_blk {margin:-200px 0 0 0 !important;}
    .blog_page .hero_blk {padding:100px 10px;}
    .blog-detail_page .hero_blk{padding:100px 10px 50px 10px;}
    .blog-detail_page .subscribe_inr h2, .blog_tab-blk .card h4 a, .related-blog .card .card-content h4 a, .location-details-blk .container p{font-size: 20px;}
    .blog-detail_page .subscribe_blk {margin: 0px 10px 50px 10px !important;width: auto;}
    .blog-hero-blk .blog-top-content h2 {font-size: 24px;}
    .social-icons {text-align: left;padding: 20px 0px;}
    .blog-bottom-content p {font-size: 12px;}
    .related-blog .related-blog-heading, .replace_system_content h3{font-size: 24px;}
    .related-blog .card h4{font-size: 20px;}
    .referral-program-page .hero_blk, .privacy-policy .hero_blk, .license .hero_blk{padding:100px 10px 50px 10px;}
    .referral-program-page .referral-program-save-blk .join-referral-program_heading h2{font-size:24px;margin-bottom:20px;}
    .home-slomins-support .section_heading h2 {font-size: 24px;margin-bottom:0px;}
    .home-slomins-support .section_heading{margin-bottom:20px;}
    .referral-program-page .referral-program-save-blk .step{margin:10px 0px;padding:5px 20px;}
    .home-slomins-support-inr ._slm-item h4{font-size: 16px;margin:5px 0;}
    .home-slomins-support-inr ._slm-item p:last-child{margin-bottom:0;}
    .referral-program-page .customer_reviews_blk h2, .why-choose-the-slomins-shield .customer_reviews_blk h2, .customer_reviews_blk h2, .customer_review_blk .sec_title h2, .referral-program-page .referral-program-save-blk .join-referral-program_content h2, .referral-program-page .referral-program-save-blk .join-referral-program_heading h2{font-size: 24px;}
    .customer_review_blk .sec_title h2{margin-bottom:10px;}
    .referral-program-page .odd{flex-direction: column-reverse;}
    .referral-program-save-blk ol li::before {margin-right: 0;padding: 2px 6px;font-size: 12px;width: 20px;height: 20px;position: absolute;left: 0;top: 2px;}
    .referral-program-save-blk ol li {padding-left: 30px;}
    .home-slomins-support-inr ._slm-item img{width: 64px;height: 64px;}
    .referral-program-page .subscribe_blk {margin: 0 !important;width: auto;}
    .referral-program-page .subscribe_blk, .request_quote_blk{padding:30px 0;}
    .home_security_products-tabs-blk .card .card-content {padding: 20px;}
    .home_security_products-tabs-blk .nav-link svg{display:inline-block;}
    .get_slomin_shield_blk .white_btn{margin-bottom:0;}
    .home_security_products-tabs-blk .card {max-width: 500px;margin: 0 auto;}
    .home_security_products-tabs-blk .card .image-wrapper {background: #b5b1b0;}
    .home_automation_page .hero_blk, .location-page .hero_blk, .term-of-use .hero_blk{padding:100px 10px 50px 10px;}
    .hero_blk .hero_box_outer{max-width: 100%;}
    .home_automation_page .home_automation-tabs-blk, .home-slomins-support, .commitment_blk, .company_history_blk, .service_info_blk{padding: 30px 0;}
    .home_automation_page .home_automation-tabs-blk .nav-pills{flex-wrap:wrap;}
    .home_automation-tabs-blk .nav-pills .nav-item {margin:5px 0;width:100%;}
    .home_automation-tabs-blk .nav-link{padding: 10px 20px;font-size: 16px;}
    .home_security-inr-sec .nav-pills{margin-bottom: 10px;}
    .home_automation-tabs-blk .tab-content{padding: 20px;}
    .home_automation-cards, .customer_review_blk, .referral-program-page .referral-program-save-blk{padding: 30px 0;}
    .home_automation-cards-img1{border-radius: 0 45px 45px 45px;margin-bottom:15px;}
    .home_automation-cards-img2{border-radius:45px 0 45px 45px;margin-top: 20px;}
    .learn_more_btn_blk {margin-top: 10px;}
    .home_automation_security-camera_img{margin-top: 30px;}
    .hero_blk .hero_box{padding: 20px;text-align: center;}
    .home_mob-automation-tabs-blk .nav-pills .nav-link{font-size: 16px;}
    .home-slomins-support .our-offer-items .equal-height {margin-bottom: 10px;}
    .home-slomins-support .our-offer-items .equal-height:last-child{margin-bottom:0;}
    .home_security-camera_page .hero_blk {background: url('../public/img/Remote-App-Main-Header.jpg') no-repeat right center/cover;padding:100px 10px 50px 10px;}
    .home_professional-monitoring_page .slomins-professional-card, .slomins-professional .alert{margin-bottom:10px;}
    .home_professional-monitoring_page .request-for-quote{margin: 0 10px;width: calc(100% - 20px);}
    .home_professional-monitoring_page .got_covered_content, .dedicated-staff_content{padding-top:25px;}
    .got_covered_blk .row .col-12:last-child, .img_blk_grey .row .col-12:last-child, .img_txt_blk_grey .row .col-12:first-child{order: 1;}
    .got_covered_blk .row .col-12:first-child, .img_blk_grey .row .col-12:first-child, .img_txt_blk_grey .row .col-12:last-child{order: 2;}
    .home_security-camera_page .home_security-camera_manage_content h2{margin-top:0;}
    .trust_high_security_content {padding: 20px 0 0 0;}
    .stickyHeader {top: 0px;-webkit-animation: topMenuSlideDown .5s ease-in-out;-moz-animation: topMenuSlideDown .5s ease-in-out;-ms-animation: topMenuSlideDown .5s ease-in-out;-o-animation: topMenuSlideDown .5s ease-in-out;animation: topMenuSlideDown .5s ease-in-out;}
    .join_family_content p br{display:none;}
    .blog_page .blog_tab-blk .nav-pills {flex-wrap: wrap;border-radius: 20px;padding:20px;justify-content: center;}
    .blog_page .blog_tab-blk .nav-pills li {width: 100%;}
    .blog_page .blog_tab-blk .nav-pills li .nav-link {text-align: center;font-size: 16px;padding: 10px 15px;}
    .products-list-home .row .col-12 {display: block;}
    .blog_details_page .hero_content h1, .thank_you_content h1{font-size: 26px;}
    .blog_details_page .blog-content-blk, .terms_content{margin:30px 0px;}
    .back_btn_blk{margin-bottom: 0;}
    .blog-top-blk .social-icons {padding: 0;}
    .blog_details_page .blog-bottom-content h1, .blog_details_page .blog-bottom-content h2, .blog_details_page .blog-bottom-content h3, .blog_details_page .blog-bottom-content h4, .blog_details_page .blog-bottom-content h1 b, .blog_details_page .blog-bottom-content h2 b, .blog_details_page .blog-bottom-content h3 b, .blog_details_page .blog-bottom-content h4 b, .news_content_area h3, .side_header h5{font-size: 20px;}
    .blog_page .products-list-home .card, .related-blog .card{margin-bottom:15px;}
    .related-blog-inr .row>div:last-child .card{margin-bottom:0;}
    .blog_tab-blk .card h4, .related-blog .card .card-content h4 {font-size: 20px;line-height: 24px;}
    .button_hero-blk #selectInput {width: 100%;padding: 10px 20px;margin-bottom:15px;}
    .location-page .button_hero-blk .red_btn {margin-left: 0;}
    .location-page-cards .row>div, .related-blog .related-blog-heading{margin-bottom: 15px;}
    .location-page-cards {padding: 30px 0 15px 0;}
    figure.loc_img_effect figcaption::before, figure.loc_img_effect figcaption::after {opacity:0 !important;}
    figure.loc_img_effect p {padding: 5px 0 !important;opacity: 1 !important;transition: none !important;transform: none !important;}
    figure.loc_img_effect img {opacity: 0.4 !important;}
    figure.loc_img_effect h2{padding-top: 30% !important;transform: none !important;}
    .loc_img_box .white_btn{font-size:14px;padding:10px 20px;}
    .referral-program-page .hero_blk h1, .referral-program-page .hero_blk .hero_inr p{width:100%;text-align:center;}
    .odd .join-referral-program_img, .join-referral-program_img{text-align:center;}
    .join-referral-program_content{padding-left:0;padding-top:15px;}
    .need_help_box{margin-top:30px;}
    .footer_copyright_wrap{margin-top:20px;}
    .contact-box-blk .row>div .box-feature{margin-bottom:10px;}
    .contact-box-blk .row>div:last-child .box-feature{margin-bottom:0;}
    .box-feature-icon img{max-width:60px;}
    .box-feature h5{margin:15px 0px;}
    .terms_content h5{margin-top:0;}
    .soc_links_bottom{margin-top:10px;}
    .home_security_products-detail-hero .row>div:first-child{order:2;}
    .home_security_products-detail-hero{padding:100px 10px 30px 10px;}
    .key-features_box{margin-bottom: 15px;}
    .thank_you_blk{padding:15px 0;}
    .thank_you_content h1{margin-bottom:30px;}
    .thank_you_content img{max-width:250px;}
    .thank_you_content{padding:20px;border-radius:10px;}
    .location-available-service-inr .location-list-content{padding:0 10px;}
    .compare_oil_blk{padding:50px 0;}
    .news-page-header h2{font-size:26px;}
    .compare_oil_blk .row>div:first-child, .compare_oil_blk .row>div{padding-right:15px;padding-left:15px;}
    #marketing-sidebar{margin-top:20px;}
}
@media (max-width:576px) {
    .quote_form .nav-pills .nav-link {min-width: inherit;}
    .quote_form .nav-pills li {width: 100%;margin-bottom: 10px;}
    .quote_form .nav-pills li button {width: 100%;}
    .quote_form {padding: 25px 15px 10px 15px;}
    .company_history_blk .nav-pills .nav-item {width: calc(50% - 10px);}
    .why-choose-the-slomins-shield .button_hero-blk {justify-content: center;}
    .hero_box a{margin-bottom:15px;}
    .hero_box a:last-child{margin-bottom:15px;}
    .inner_content_wrapper .inner_content{flex-direction:column;}
    .inner_content .account_content_box{margin-right:0;margin-bottom:20px;}
    .inner_content .account_content_box:last-child{margin-bottom:0;}
    .modal-header .modal-title {font-size: 20px;line-height: normal;}
    .back_btn_blk {margin-bottom: 10px;}
    .blog_details_page .social-icons ul {justify-content: flex-start;margin-top: 15px;}
    .referral-program-page .home-slomins-support .our-offer-items>div{display:block;}
    .quote_btn_wrap{flex-direction:column;}
    .quote_btn_wrap a{margin-right:0;}
    body .get_slomin_shield-detial_blk .red_btn{margin-right:0;margin-bottom:15px;}
    .header_nav ul li.ph_number, .header_nav ul li.my_acc_btn, .toggle_menu_btn{margin-left:10px;}
}


/*SeedProd landing pages start*/
.landing_pg_hero.hero_blk{background:#fff;padding:50px 15px;position: relative;background-size:cover;}
.landing_pg_hero.hero_blk::after{display:none;}
.landing_pg_hero.hero_blk .hero_box{background:rgba(177, 13, 40, 1);padding:30px;}
.landing_pg_hero .note-sec{color:#2b2b2b;font-size:14px;font-weight:600;border-radius:100px;background:#ffaa4d;padding:5px 20px;width:fit-content;display:inline-block;margin-bottom:10px;text-transform:uppercase;}
.landing_pg_hero.hero_blk .hero_box h1{margin-bottom:10px;margin-top:5px;}
.landing_pg_hero.hero_blk .hero_box h1 span{font-weight:300;}
.landing_pg_hero.hero_blk .hero_box p:last-child{margin-bottom:0;}
.hr_img_blk{padding:0;background:transparent;border-radius:10px;margin:0 20px;display:flex;justify-content:center;align-items:center;}
.hr_img_blk img{max-width:450px;}
.landing_pg_hero.hero_blk .hero_box .white_btn:hover svg path{fill:white;}
.landing_header .header_nav>ul{align-items:center;}
.landing_header .header_nav>ul>li>a{line-height:normal;padding:10px 20px;border-radius:100px;border:1px solid #b10d28;color:#b10d28;}
.landing_header .header_nav>ul>li:hover{background:transparent;}
.landing_header .header_nav>ul>li:not(.cir_btn)>a:hover{background:#b10d28;}
.ft_legal_copy p{font-size:12px;text-align:center;margin:10px 0;}
.ft_legal_copy p:last-child{margin-bottom:0;}
.footer.slomins-spotify-footer{padding:50px 0;}
.our_services_plans{padding:50px 0;background:#f2f2f2;}
.our_services_plans .card .card-content{padding:30px;background:#fff;}
.our_services_plans .card .card-content h4>span{color:#b10d28;font-weight:600;line-height:normal;}
.our_services_plans .card .card-content p{margin-bottom:0;}
.our_services_plans .card .card-content ul{padding-left:20px;margin-bottom:20px;}
.our_services_plans .card .card-content h4{font-size:20px;}
.our_services_plans .card .card-content a{display:inline-block;}
.our_services_plans .card{border-radius:10px;overflow:hidden;}
.our_services_plans .row>div.col-md-6{display:flex;}
.our_services_plans .card .image-wrapper{overflow:hidden;}
.our_services_plans .card .image-wrapper img{width:100%;max-height:250px;object-fit:cover;transition:.4s ease-in-out;}
.our_services_plans .card:hover .image-wrapper img{transform:scale(1.1);}
.claim_form_blk{padding:50px 0;background:#b10d28;}
.claim_form_blk h2{font-size:24px;color:#fff;}
.claim_form_blk p, .claim_form_blk p a, .claim_form_blk h2 a{color:#fff;}
.claim_form_blk p:last-child{margin-bottom:0;}
.claim_form_info{padding-right:50px;}
.claim_form{background:#fff;padding:40px;border-radius:10px;}
.claim_form>div, .claim_form>div>.row>div, .claim_form .row>div{margin-bottom:15px;}
.claim_form>div:last-child, .claim_form>div>.row>div:last-child, .claim_form .row>div:last-child{margin-bottom:0;}
.ppc_special_pg .claim_form_info{text-align:center;padding-right:0;}
.ppc_special_pg .claim_form_info .white_btn:hover{border-color:#fff;}
.ppc_special_pg .claim_form_info .white_btn{display:inline-block;}
.landing_pg_hero.hero_blk .hero_box .sml_txt{display:block;font-size:12px;color:#fff;margin-top:15px;}
.free_doorbell_camera_pg .hr_img_blk img{max-width:150px;}
.audacy_pg .hr_img_blk img, .iheartradio_pg .hr_img_blk img, .ppc_wireless_security_pg .hr_img_blk img, .ppc_freedoorbell_pg .hr_img_blk img{max-width:120px;}
.free_equipment_blk .four_box_blk .slm-item p:last-child{margin-bottom:0;}
.home_safety_blk{background:#f2f2f2;padding:50px 0;}
.home_safety_blk .slm-item{background:#fff;padding:20px;border-radius:10px;border:1px solid #fff;}
.home_safety_blk .sec_title h2{font-size:30px;}
.home_safety_blk .slm-item img{max-width:60px;margin-bottom:15px;}
.home_safety_blk .slm-item h4{font-size:24px;}
.home_safety_blk .slm-item p{margin-bottom:0;}
.your_door_blk{padding:50px 0;}
.free_doorbell_camera_pg .our_services_plans .card .card-content{padding:20px;}
.more_btn_blk{margin-top:30px;text-align:center;}
.more_btn_blk a{display:inline-block;}
.free_doorbell_camera_pg .customer_reviews .reviews_item{background:#feeceb;}
.free_doorbell_camera_pg .customer_reviews_blk{background:#fff;}
.free_doorbell_camera_pg .faq_blk{background:#f2f2f2;}
.free_equipment_install_pg .hero_blk, .next_day_install_pg .hero_blk{background:url('../public/img/equipment-installation.jpg') no-repeat;padding:100px 15px;position:relative;background-size:cover;}
.free_equipment_install_pg .hero_blk::after, .next_day_install_pg .hero_blk::after{content:'';position:absolute;width: 100%;height:100%;top:0;left:0;background:rgba(0, 0, 0, 0.8);opacity:0.7;z-index:1;display:block;}
.free_equipment_install_pg .hero_blk .hero_box, .next_day_install_pg .hero_blk .hero_box{background:rgba(177, 13, 40, 0.8);}
.free_equipment_install_pg .hero_blk .hero_box_outer{max-width:60%;}
.free_equipment_install_pg .home_safety_blk{background:#fff;z-index:1;position:relative;}
.free_equipment_install_pg .home_safety_blk .slm-item{border:1px solid #ddd;}
.home_safety_blk .slm-item{transition:all 0.3s ease-in-out;}
.home_safety_blk .slm-item:hover{border-color:#B10D28;transition:all 0.3s ease-in-out;transform:translateY(-5px);}
.security_features_blk{padding:50px 0;background:#f2f2f2;}
.free_equipment_install_pg .our_services_plans{background:#fff;}
.free_equipment_install_pg .faq_blk{background:#f2f2f2;}
.free_equipment_install_pg .our_services_plans .card .card-content{padding:20px;}
.security_features_blk .row:not(.header_row):nth-child(odd) .security_features_img{border-radius:0 45px 45px 45px;overflow:hidden;}
.security_features_blk .row:not(.header_row):nth-child(odd) .security_features_content{padding-left:50px;}
.security_features_blk .row:not(.header_row):nth-child(even) .security_features_img{border-radius:45px 0 45px 45px;overflow:hidden;}
.security_features_blk .row:not(.header_row):nth-child(even) .security_features_content{padding-right:50px;}
.top_tiles{margin-top:-110px;margin-bottom:50px;position:relative;z-index:5;}
.top_tiles ul{list-style:none;display:flex;justify-content:center;padding-left:0;}
.top_tiles ul li{background:#ffaa4d;color:#2b2b2b;padding:20px;border-radius:5px;margin:0 5px;width:33.3334%;display:flex;justify-content:center;align-items:center;font-size:24px;font-weight:600;}
.audacy_pg .security_features_blk, .audacy_pg .customer_reviews_blk{background:#fff;}
.audacy_pg .customer_reviews .reviews_item{background:#feeceb;}
.audacy_pg .faq_blk{background:#f2f2f2;}
.audacy_pg .alert{margin-bottom:0;text-align:center;border-radius:0;}
.next_day_install_pg .home_safety_blk, .next_day_install_pg .our_services_plans, .affordablesecurity_pg .home_safety_blk{background:#fff;}
.next_day_install_pg .security_features_blk{background:#f2f2f2;}
.next_day_install_pg .home_safety_blk .slm-item, .affordablesecurity_pg .home_safety_blk .slm-item{border-color:#ddd;}
.next_day_install_pg .home_safety_blk .slm-item:hover, .affordablesecurity_pg .home_safety_blk .slm-item:hover{border-color:#B10D28;}
.affordablesecurity_pg .hero_blk.landing_pg_hero, .free_security_system_pg .hero_blk.landing_pg_hero, .ppc_social_family_security_pg .hero_blk.landing_pg_hero, .ppc_social_propertysecurity_pg .hero_blk.landing_pg_hero, .ppc_heirloomsecurity_pg .hero_blk.landing_pg_hero, .ppc_gm_freedoorbell_pg .hero_blk.landing_pg_hero, .ppc_freedoorbell_pg .hero_blk.landing_pg_hero, .ppc_order_afforablesecurity_pg .hero_blk.landing_pg_hero, .ppc_freeinstall_pg .hero_blk.landing_pg_hero{padding:50px 15px 90px 15px;}
.affordablesecurity_pg .top_tiles, .free_security_system_pg .top_tiles, .ppc_social_propertysecurity_pg .top_tiles, .ppc_heirloomsecurity_pg .top_tiles, .ppc_gm_freedoorbell_pg .top_tiles, .ppc_freedoorbell_pg .top_tiles, .ppc_order_afforablesecurity_pg .top_tiles, .ppc_freeinstall_pg .top_tiles{margin-top:-90px;}
.free_equipment_blk {background: #f2f2f2;padding: 50px 0;}
.monitoring_starting_blk {background:url('../public/img/call-me-img.jpg') no-repeat;padding:100px 0;}
.monitoring_starting_blk .slm-item h4 {color: #fff;}
.monitoring_starting_blk .slm-item .sml_txt {color: #fff;font-size: 12px;margin-bottom: 0;margin-top: 10px;}
.monitoring_starting_blk .slm-item .more_btn_blk .red_btn svg path {fill: #fff;}
.monitoring_starting_blk .slm-item .more_btn_blk .red_btn:hover svg path {fill: #B10D28;}
.free_equipment_blk {background: #FFF;padding: 50px 0;}
.free_equipment_blk .four_box_blk .slm-item {padding: 25px;border-radius: 10px;background: #fdebea;border: 1px solid #ecbebc;margin-bottom:25px;}
.four_box_blk>div{display:flex;}
.single_large_box .slm-item {display: flex;justify-content: space-between;align-items: center;background: #fdebea;padding: 25px;border-radius: 10px;border: 1px solid #ecbebc;}
.single_large_box .slm-item .bx_imgs {display: flex;align-items: center;width: 50%;justify-content: space-around;}
.bx_content {width: 50%;}
.single_large_box .slm-item .bx_imgs img {max-height: 200px;}
.single_large_box>div {max-width: 60%;}
.bx_content h4{font-size: 30px;margin-bottom: 10px;line-height: 1.1;}
.bx_content p{margin-bottom: 0;}
.bx_content .sml_txt {font-size: 12px;}
.affordablesecurity_pg .home_safety_blk .our-offer-items>div, .ppc_social_propertysecurity_pg .home_safety_blk .our-offer-items>div, .ppc_heirloomsecurity_pg .home_safety_blk .our-offer-items>div, .ppc_freedoorbell_pg .home_safety_blk .our-offer-items>div, .ppc_order_afforablesecurity_pg .home_safety_blk .our-offer-items>div, .ppc_freeinstall_pg .home_safety_blk .our-offer-items>div{display:flex;justify-content:center;}
.affordablesecurity_pg .home_safety_blk .our-offer-items{justify-content:center;}
.iheartradio_pg .security_features_blk, .iheartradio_pg .customer_reviews_blk{background:#fff;}
.iheartradio_pg .customer_reviews .reviews_item{background:#feeceb;}
.iheartradio_pg .faq_blk{background:#f2f2f2;}
body .seedprod_form .css-13cymwt-control, body .seedprod_form .css-t3ipsp-control{min-height:37.6px;}
.monitoring_starting_blk .slm-item h2 {color: #fff;}
.monitoring_starting_blk .slm-item h2 a {color: #b10d28;text-decoration: none;}

.affordable_monitoring_pg .hero_blk{background: url('../public/img/alarm-mockup-4.jpg') no-repeat right top;padding: 50px 15px;position: relative;background-size: cover;}
.affordable_monitoring_pg .hero_blk .hero_box_outer{max-width:60%;margin:0;}
.affordable_monitoring_pg .home_safety_blk{background:#fff;}
.affordable_monitoring_pg .home_safety_blk .our-offer-items>div{display:flex;}
.affordable_monitoring_pg .home_safety_blk .slm-item{border:1px solid #ccc;transition: all 0.3s ease-in-out;}
.affordable_monitoring_pg .home_safety_blk .slm-item:hover{border-color:#B10D28;transition:all 0.3s ease-in-out;transform:translateY(-5px);}
.slomins_promise_blk{padding:50px 0;}
.affordable_monitoring_pg .faq_blk{background:#f2f2f2;}
.slomins_promise_content {padding-left: 30px;}
.slomins_promise_content ul {padding-left: 20px;margin-bottom: 0;}
.slomins_promise_content p.red_txt {font-size: 30px;margin-bottom: 0;}
.slomins_promise_content h3 {font-size: 22px;}
.slomins_promise_img{border-radius:0 45px 45px 45px;overflow:hidden;}
.ppc_social_family_security_pg .top_tiles, .ppc_wireless_security_pg .top_tiles{margin-top:-90px;}
.free_security_system_pg .home_safety_blk .our-offer-items>div, .ppc_social_family_security_pg .home_safety_blk .our-offer-items>div, .ppc_wireless_security_pg .home_safety_blk .our-offer-items>div{display:flex;justify-content:center;}
.ppc_social_family_security_pg .monitoring_starting_blk{background:url('../public/img/Home-Index-Header.jpg') no-repeat right top;padding:200px 0;position: relative;}
.ppc_social_family_security_pg .monitoring_starting_blk:after{content:'';width:100%;height:100%;position:absolute;left:0;
top:0;background:rgba(0, 0, 0, 0.6);}
.ppc_social_family_security_pg .monitoring_starting_blk .slm-item{position:relative;z-index:2;}
.monitoring_starting_blk .slm-item p{color:#fff;}
.ppc_wireless_security_pg .landing_pg_hero.hero_blk{padding:50px 15px 90px 15px;}
.free_equipment_blk .four_box_blk .slm-item p:last-child{margin-bottom:0;}
.doorbell_cam_blk{background:#fff;padding:50px 0;}
.doorbell_cam_img{text-align:center;}
.doorbell_cam_img img{max-height:250px;}
.iheart_pg .security_features_blk{background:#fff;}
.ppc_social_propertysecurity_pg .hr_img_blk img{max-width:600px;}
.ppc_social_propertysecurity_pg .monitoring_starting_blk{background:url('../public/img/property-background-scaled.jpg') no-repeat right top;padding:100px 0;position: relative;}
.ppc_social_propertysecurity_pg .monitoring_starting_blk:after, .ppc_heirloomsecurity_pg .monitoring_starting_blk::after, .ppc_order_afforablesecurity_pg .monitoring_starting_blk::after, .ppc_freeinstall_pg .monitoring_starting_blk::after{content:'';width:100%;height:100%;position:absolute;left:0;top:0;background:rgba(0, 0, 0, 0.6);}
.ppc_social_propertysecurity_pg .monitoring_starting_blk .slm-item, .ppc_heirloomsecurity_pg .monitoring_starting_blk .slm-item, .ppc_order_afforablesecurity_pg .monitoring_starting_blk .slm-item, .ppc_freeinstall_pg .monitoring_starting_blk .slm-item{position:relative;z-index:2;}
.ppc_heirloomsecurity_pg .monitoring_starting_blk{background:url('../public/img/Family-In-Kitchen-1-scaled.jpg') no-repeat center top;padding:100px 0;position: relative;}
.ppc_gm_freedoorbell_pg .free_equipment_blk{background:#f2f2f2;}
.ppc_gm_freedoorbell_pg .free_equipment_blk .four_box_blk .slm-item, .ppc_gm_freedoorbell_pg .single_large_box .slm-item{background:#fff;border:1px solid #ddd;}
.ppc_gm_freedoorbell_pg .free_equipment_blk .more_btn_blk .sml_txt{margin-bottom:0;font-size:12px;margin-top:10px;}
.ppc_gm_freedoorbell_pg .free_equipment_blk .more_btn_blk a path{fill:#fff;}
.ppc_gm_freedoorbell_pg .free_equipment_blk .more_btn_blk a:hover path{fill:#B10D28;}
.ppc_freedoorbell_pg .home_safety_blk .sec_title p{max-width:80%;margin:0 auto;}
.ppc_order_afforablesecurity_pg .monitoring_starting_blk, .ppc_freeinstall_pg .monitoring_starting_blk{background:url('../public/img/Alarm-Monitoring-Slide.jpg') no-repeat;padding:100px 0;position:relative;}
.ppc_order_afforablesecurity_pg .monitoring_starting_blk .slm-item h2 a, .ppc_freeinstall_pg .monitoring_starting_blk .slm-item h2 a{color:#fff;text-decoration:underline;}
.claim_form_bg .claim_form_info{text-align:center;}
.claim_form_bg .claim_form_info .white_btn{display:inline-block;}
.claim_form_bg{padding:100px 0;background:url('../public/img/claim-free-bg.jpg') no-repeat;position:relative;}
.claim_form_bg::after{content:'';width:100%;height:100%;position:absolute;left:0;top:0;background:rgba(0, 0, 0, 0.3);}
.claim_form_bg .claim_form_inr{position:relative;z-index:2;}
.top_priority_blk{background:url('../public/img/Alarm-Install-Hero-Image-2a.jpg') no-repeat;padding:100px 0;position:relative;}
.top_priority_info{max-width:50%;}
@media (max-width:1199px){
    .free_equipment_install_pg .hero_blk .hero_box_outer, .affordable_monitoring_pg .hero_blk .hero_box_outer{max-width: 100%;}
}
@media (max-width:991px) {
    .hr_img_blk img{max-width:100%;}
    .landing_header .header_nav ul li.ph_number a{width: 40px;height: 40px;border: 1px solid #b10d28;border-radius: 200px;padding: 0;background: url('../public/img/telephone_icon.svg') no-repeat center;background-size:20px;}  
    .landing_header .header_nav ul li.ph_number a:hover{background:#b10d28 url('../public/img/telephone_icon_white.svg') no-repeat center;background-size:20px;}
    .our_services_plans .card{margin-bottom:20px;}
    .claim_form_info{padding-right:0;margin-bottom:20px;}

    .free_equipment_blk .four_box_blk .slm-item{padding:15px;margin-bottom:10px;width:100%;}
    .free_equipment_blk .four_box_blk .slm-item p:last-child{margin-bottom:0;}
    .single_large_box>div{max-width:100%;}
    .single_large_box .slm-item{padding:10px;width:100%;flex-direction:column;}
    .single_large_box .slm-item .bx_imgs{flex-direction:column;width:100%;margin-bottom:20px;}
    .bx_content{width:100%;}
    main{overflow-x:hidden;}
    .security_features_img{margin:15px 0;}
    .ppc_heirloomsecurity_pg .monitoring_starting_blk{padding:100px 0;}
    .top_tiles ul li{text-align: center;}
    .ppc_freedoorbell_pg .home_safety_blk .sec_title p, .top_priority_info{max-width:100%;}
}
@media (max-width:767px) {
    main{overflow-x:hidden;}   
    .claim_form_blk{padding:30px 5px 20px 5px;}
    .claim_form_info{padding-right:0;margin-bottom:20px;text-align:center;}
    .spotify_pg .hero_blk, .landing_pg_hero.hero_blk, .affordablesecurity_pg .hero_blk.landing_pg_hero, .affordablesecurity_pg .hero_blk.landing_pg_hero, .free_security_system_pg .hero_blk.landing_pg_hero, .ppc_social_family_security_pg .hero_blk.landing_pg_hero, .ppc_wireless_security_pg .landing_pg_hero.hero_blk, .ppc_social_propertysecurity_pg .hero_blk.landing_pg_hero, .ppc_heirloomsecurity_pg .hero_blk.landing_pg_hero, .ppc_gm_freedoorbell_pg .hero_blk.landing_pg_hero, .ppc_freedoorbell_pg .hero_blk.landing_pg_hero, .ppc_order_afforablesecurity_pg .hero_blk.landing_pg_hero, .ppc_freeinstall_pg .hero_blk.landing_pg_hero{padding:30px 10px;}
    .landing_pg_hero.hero_blk .hero_content_img{margin-top:30px;}
    .our_services_plans{padding:30px 0 20px 0;}
    .our_services_plans .card{margin-bottom:10px;}
    .landing_header:not(.only_phone_header) .logo_toggle_wrap{justify-content:center;margin-bottom:20px;}
    .landing_header .header_nav>ul{justify-content:center;padding-left:0;}
    .landing_header .header_nav>ul li{margin:0 5px;}
    .landing_header.stickyHeader+main{padding-top:152px;}
    .only_phone_header.landing_header.stickyHeader+main{padding-top:70px;}
    .landing_header{padding:15px 0;}
    .only_phone_header.landing_header{padding:5px 0;}
    .hr_img_blk img{max-width:100%;}
    .footer.slomins-spotify-footer, .home_safety_blk, .your_door_blk, .security_features_blk{padding:30px 0;}
    .landing_pg_hero.hero_blk .hero_box{padding:20px;}
    .landing_pg_hero.hero_blk .hero_box a{margin-bottom:0;}
    .surveillance_blk>div:first-child, .compatability_blk>div:first-child{order:2;}
    .surveillance_blk>div:last-child, .compatability_blk>div:last-child{order:1;}
    .your_door_blk .sec_title h2, .claim_form_blk h2, .faq_blk .sec_title h2, .home_safety_blk .sec_title h2, .bx_content h4, .slomins_promise_content p.red_txt, .monitoring_starting_blk .slm-item h2, .free_equipment_blk h2, .doorbell_cam_blk .sec_title h2{font-size:24px;}
    .home_safety_blk .slm-item h4{font-size:20px;}
    .claim_form{padding:30px 20px;}
    .claim_form_info p{font-size:14px;}
    .more_btn_blk{margin-top:10px;}
    .home_safety_blk .slm-item{margin-bottom:10px;}
    .home_safety_blk .our-offer-items>div:last-child>.slm-item{margin-bottom:0;}
    .only_phone_header .logo_toggle_wrap {margin-bottom: 0;}
    .only_phone_header.landing_header .header_nav>ul {justify-content: flex-end;}
    .top_tiles {margin-top: 0;margin-bottom: 30px;}
    .top_tiles ul{flex-wrap: wrap;}
    .top_tiles ul li{margin:5px 0;width:100%;font-size:16px;padding:10px;}
    .security_features_blk .row:not(.header_row):nth-child(even) .security_features_content {padding-right: 0;text-align: left;}
    .security_features_blk .row:not(.header_row):nth-child(odd) .security_features_content {padding-left: 0;text-align: left;}
    .security_features_blk .row:not(.header_row):nth-child(even) .security_features_img, .security_features_blk .row:not(.header_row):nth-child(odd) .security_features_img {border-radius:10px;margin-bottom:15px;}
    .security_features_blk .row:not(.header_row):nth-child(even)>div:first-child {order: 2;}
    .security_features_blk .row:not(.header_row):nth-child(even)>div:last-child {order: 1;}
    .security_features_blk .row:not(.header_row) {margin-bottom: 20px;}
    .security_features_blk .row:not(.header_row):last-child{margin-bottom:0;}
    .our_services_plans .card .card-content{padding:20px;}
    .affordablesecurity_pg .top_tiles, .free_security_system_pg .top_tiles, .ppc_social_propertysecurity_pg .top_tiles, .ppc_heirloomsecurity_pg .top_tiles, .ppc_freedoorbell_pg .top_tiles, .ppc_order_afforablesecurity_pg .top_tiles, .ppc_freeinstall_pg .top_tiles{margin-top:0;}
    .monitoring_starting_blk, .ppc_social_family_security_pg .monitoring_starting_blk{padding:60px 0;}
    .free_equipment_blk, .slomins_promise_blk, .doorbell_cam_blk{padding:30px 0;}
    .free_equipment_blk .sec_title p, .home_safety_blk p, .doorbell_cam_blk .sec_title p, .doorbell_cam_content p{font-size:16px;}
    .your_door_content h3, .security_features_content h3, .slomins_promise_content h3, .monitoring_starting_blk .slm-item h4, .free_equipment_blk .four_box_blk .slm-item h4, .doorbell_cam_content h3{font-size:20px;}
    .affordable_monitoring_pg .landing_pg_hero.hero_blk .hero_box{background:rgba(177, 13, 40, 0.9);}
    .slomins_promise_content{padding-left:0;margin-top:20px;}
    .ppc_social_family_security_pg .top_tiles, .ppc_wireless_security_pg .top_tiles, .ppc_gm_freedoorbell_pg .top_tiles{margin-top:0;}
    .doorbell_cam_content{text-align:center;}
    .security_features_img{margin:0;}
    .ppc_heirloomsecurity_pg .monitoring_starting_blk, .ppc_order_afforablesecurity_pg .monitoring_starting_blk, .ppc_freeinstall_pg .monitoring_starting_blk, .top_priority_blk{padding:50px 0;}
    .claim_form_bg{padding:50px 0 30px 0;}
    .top_priority_blk{text-align:center;}
}
@media (max-width:576px) {
    .only_phone_header .col-12 {flex: 0 0 auto;width:50%;}    
}
/*SeedProd landing pages end*/