.slick-initialized .slick-slide {padding: 0px 10px;display: inline-flex;vertical-align: top;height: auto;}
.customer_reviews_blk .slick-track {display: flex;}
.validation-error {width: 100%;margin-top: 0.25rem;font-size: .875em;color: #dc3545 !important;}
.select-validation-error .css-13cymwt-control, .select-validation-error .css-13cymwt-control:hover, .select-validation-error .css-13cymwt-control:active, .select-validation-error .css-t3ipsp-control:hover, .select-validation-error .css-t3ipsp-control:active{border-color: #dc3545 !important;outline: none !important;}
.select-validation-error .css-13cymwt-control:focus{border-color: #dc3545 !important;box-shadow: 0 0 0 0.25rem rgba(220,53,69,.25) !important;}
/*Loading start*/
.overlay.loading .loading_wrap {display: block;position: relative;}
.loading_wrap .loading_spinner {animation: animRotate 1s linear infinite;background: transparent;border: 4px solid #888;border-bottom-color: #fff;border-radius: 50%;position: absolute;height: 50px;top: 50%;left: 50%;margin: -25px 0 0 -25px;opacity: .7;padding: 0;}
.loading_inner {background: #fff;box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);padding: 10px;width: 80px;height: 80px;border-radius: 8px;transform: translate(-50%, -50%);position: absolute;}
.loading_wrap .loading_spinner {animation: animRotate 3s linear infinite;border: 4px dotted #000;border-bottom-color: #ee3b33;height: 60px;
margin: -30px 0 0 -30px;width: 60px;opacity: 0.8;}
.quote_form .loading {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 1;}
.overlay_blk {width: 100%;height: 100%;position: fixed;left: 0;top: 0;background: rgba(0, 0, 0, 0.15);z-index: 0;}
.loading_inner p {font-size: 9px;margin: 25px 0 0 9px;}
  @keyframes animRotate {
    to {
        transform: rotate(1turn)
    }
}
.sales-error-box{background: #b10d28;height: 100vh;display: flex;justify-content: center;align-items: center;padding: 10px;}
.sales-error-box .card-body {width:100%;padding:40px;max-width:550px;margin:0 auto;background: #fff;border-radius: 10px;}
.sales-error-box .card {background: transparent;border: none;}
.sales-error-box .card-logo {width: 250px;margin: 0 auto;text-align:center;}
.sales-error-box .card-title {margin-top:10px;text-align: center;}
.sales-error-box .card-title h1 {font-size:10rem;font-weight: 600;text-align: center;color: #b10d28 !important;margin-bottom: 0;}

.home_btn {min-width: 260px;border-radius: 40px;height: 48px;line-height: 35px;font-weight: 600;font-size: 18px;background-color: #B10D28;border-color: #B10D28;color:#fff;border:1px solid #B10D28;text-transform:uppercase;}
.home_btn.btn-primary:hover, .home_btn.btn-primary.focus,.home_btn.btn-primary:focus, .home_btn.btn-primary:not(:disabled):not(.disabled).active, .home_btn.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {background-color: #fff;border-color: #B10D28;color:#B10D28;}

@media only screen and (min-width: 768px) {
  .sales-error-box .card-body {min-width:550px;}
}
@media only screen and (max-width: 767px) {
  .sales-error-box .card-title h1{font-size:6rem;}
  .home_btn{min-width:inherit;padding:10px 30px;height:auto;line-height:normal;}
  .sales-error-box .card-logo{width: auto;}
}